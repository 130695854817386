/**
 * Advanced example demonstrating all core MobX constructs.
 */
import { makeAutoObservable } from "mobx";

class VaultStore {
  // ====================================================
  // State
  // ====================================================
  user = null;
  count = 0;
  error = null;

  numberOfPackages = null;
  payingAll = false;
  exactTrip = {
    trip: null,
    savingFrequency: null,
  };
  modalType = null;
  showModal = false;
  paying = false;
  couponCode = " ";

  currentAccount = null;

  constructor() {
    makeAutoObservable(this);
  }

  setPackageNumber = (number) => {
    this.numberOfPackages = number;
  };
  setPaying = (state) => {
    this.payingAll = state;
  };
  updateProfileInfo = (profileInfo) => {
    this.loadingMe = true;
    this.me = profileInfo;
    this.loadingMe = false;
  };
  setExactTrip = (trip) => {
    this.exactTrip.trip = trip;
  };

  setModalType = (type) => {
    this.modalType = type;
  };

  setShowModal = (state) => {
    this.showModal = state;
  };

  updateCurrentAccount = (info) => {
    this.currentAccount = info;
  };

  setPaymentCouponCode = (code) => {
    this.couponCode = code;
  };
}

export default new VaultStore();
