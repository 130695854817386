import React, { useEffect, useState } from "react";
import { ArrowDownward } from "@material-ui/icons";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import Frame1 from "./animations/Frame1";
import Frame2 from "./animations/Frame2";
import Frame3 from "./animations/Frame3";
import { useNavigate } from "react-router-dom";
import authStore from "stores/authStore";
import { observer } from "mobx-react-lite";
import { images } from "constants";

const SideBar = ({ handleClick }) => {
  const [isCustom, setIsCustom] = useState(false);
  const [initDone, setInitDone] = useState(false);
  const [secDone, setSecDone] = useState(false);
  const [reference, setReference] = useState("");

  const { referralCode, setReferralCode } = authStore;
  useEffect(() => {
    setReference(referralCode);
  }, [referralCode]);

  useEffect(() => {
    if (secDone) {
      setTimeout(() => {
        setInitDone(false);
        setIsCustom(false);
        setSecDone(false);
      }, 2000);
    }
  }, [secDone]);

  const [slide, setSlide] = useState(0);

  useEffect(() => {
    if (!initDone) {
      const interval = setInterval(() => {
        setSlide(0);
      });
      return () => clearInterval(interval);
    } else if (initDone) {
      const interval = setInterval(() => {
        setSlide(1);
      });
      return () => clearInterval(interval);
    }
  }, [initDone]);

  const navigate = useNavigate();

  return (
    <div className="lg:w-[50%] overflow-y-hidden  relative xs:w-full bg-[#F7F7F8] py-[30px]">
      <div
        onClick={() => navigate("/")}
        className="cursor-pointer xs:px-[37px] sm:px-[57px]"
      >
        <Logo />
      </div>
      {reference === "AYTravel24" ? (
        <div className={`w-full sm:h-full h-[90vh] relative`}>
          <img className="w-full" src={images.andrew} />
          <div className="lg:hidden absolute z-[999] left-[50%] -translate-x-1/2 bottom-[10%]">
            <div
              onClick={handleClick}
              className="arrow bg-[white] flex mt-[20px] lg:hidden flex-col justify-center items-center h-[55px] w-[55px] rounded-full border border-blue-100"
            >
              <ArrowDownward />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`sm:flex overflow-x-hidden xs:px-[37px] sm:px-[57px] flex-col items-center justify-center`}
        >
          <div
            className={`w-full flex flex-col items-center justify-center transition-transform duration-1000 transform ${
              slide === 0 ? "translate-x-0" : "translate-x-full"
            }`}
          >
            {!isCustom && !initDone && (
              <Frame1 isCustom={isCustom} setIsCustom={setIsCustom} />
            )}
            {isCustom && !initDone && <Frame2 setInitDone={setInitDone} />}
          </div>
          <div
            className={`w-full flex flex-col items-center justify-center transition-transform duration-1000 transform ${
              slide === 1 ? "translate-x-0" : "translate-x-full origin-left"
            }`}
          >
            {initDone && <Frame3 setSecDone={setSecDone} secDone={secDone} />}
          </div>
          <div className="relative lg:min-h-[16vh] xs:min-h-[16vh] xs:overflow-y-hidden sm:w-[600px] lg:w-[650px] overflow-x-hidden ">
            <div
              className={`flex bg-[#F7F7F8] left-0 right-0 absolute top-0 pb-[0px]  sm:px-[57px] flex-col justify-center items-center text-center transition-transform duration-1000 transform ${
                slide === 0 ? "translate-x-0" : "translate-x-full"
              }`}
            >
              <div className="text-[18px] font-bold text-[#093549]">
                Access packages & create custom trips
              </div>
              <div className="mt-[4px] text-[15px] text-[#475467] text-center">
                Explore ready made packages from the best deals in the market
                for you. Tailor your perfect adventure with our custom trip
                creation tool
              </div>
            </div>
            <div
              className={`flex bg-[#F7F7F8] absolute left-0 right-0 top-0 pb-[30px] xs:px-[37px] sm:px-[57px] flex-col justify-center items-center text-center transition-transform duration-1000 transform ${
                slide === 1 ? "translate-x-0" : "translate-x-full"
              }`}
            >
              <div className="text-[18px] font-bold text-[#093549]">
                Customize a payment plan for your dream trip
              </div>
              <div className="mt-[4px] text-[15px] text-[#475467] text-center">
                Choose a payment frequency that works for you to make your dream
                trip happen. You can pay in installments before the departure
                date is close.
              </div>
            </div>
          </div>
          <div className="flex gap-[15px] justify-center items-center">
            {new Array(2).fill(0).map((_, j) => (
              <div key={j}>
                {new Array(1).fill(0).map((_, i) => (
                  <div
                    className={`${
                      j === slide ? "bg-blue-100" : "bg-[#D4DFE4]"
                    }  rounded-full w-[87px] h-[7px]`}
                  />
                ))}
              </div>
            ))}
          </div>
          <div className="lg:hidden absolute z-[999] left-[50%] -translate-x-1/2 bottom-[30%]">
            <div
              onClick={handleClick}
              className="arrow bg-[white] flex mt-[20px] lg:hidden flex-col justify-center items-center h-[55px] w-[55px] rounded-full border border-blue-100"
            >
              <ArrowDownward />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(SideBar);
