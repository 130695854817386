import dubai from "../assets/img/dubai.jpg";
import paris from "../assets/img/paris.jpg";
import newyork from "../assets/img/newyork.jpg";
import london from "../assets/img/london.jpg";
import like from "../assets/img/like.svg";
import unlike from "../assets/img/unlike.svg";
import notFavorite from "../assets/img/notFavorite.svg";
import card1 from "../assets/img/card1.svg";
import card2 from "../assets/img/card2.svg";
import green from "../assets/img/green.svg";
import success from "../assets/img/success.svg";
import beach from "../assets/img/beach.svg";
import bannerimg from "../assets/img/bannerimg.svg";
import bannerbg from "../assets/img/bannerbg.svg";
import man1 from "../assets/img/man1.jpg";
import man2 from "../assets/img/man2.jpg";
import lady from "../assets/img/lady.jpg";
import google from "../assets/img/google.svg";
import wavy from "../assets/img/wavy.svg";
import testimonialbg from "../assets/img/testimonialbg.svg";
import whitebg from "../assets/img/whitebg.svg";
import kikelomo from "../assets/img/kikelomo.PNG";
import ereretola from "../assets/img/ereretola.png";
import temidayo from "../assets/img/temidayo.png";
import zaana from "../assets/img/zaana.png";
import arrow from "../assets/img/arrow.svg";
import check from "../assets/img/check.svg";
import undo from "../assets/img/undo.svg";
import trip from "../assets/img/trip.svg";
import arrowRight from "../assets/img/arrow-right.svg";
import cursor from "../assets/img/cursor.svg";
import checkMark from "../assets/img/check-mark.svg";
import bars from "../assets/img/bars.svg";
import copy from "../assets/icons/copy.svg";
import logout from "../assets/img/logout.svg";
import search from "../assets/img/search.svg";
import cal from "../assets/icons/cal.svg";
import users from "../assets/icons/users.svg";
import avatar from "../assets/icons/avatar.svg";
import lock from "../assets/icons/lock.svg";
import plane from "../assets/icons/plane.svg";
import call from "../assets/icons/call.svg";
import loc from "../assets/icons/loc.svg";
import bot from "../assets/icons/bot.svg";
import send from "../assets/icons/send.svg";
import wave from "../assets/icons/wave.svg";
import smile from "../assets/icons/smile.svg";
import thumb from "../assets/icons/thumb.svg";
import pack from "../assets/icons/pack.svg";
import supp from "../assets/icons/supp.svg";
import card from "../assets/icons/card.svg";
import visa from "../assets/icons/visa.svg";
import acc from "../assets/icons/acc.svg";
import planes from "../assets/icons/planes.svg";
import botpic from "../assets/icons/bot-pic.svg";
import refresh from "../assets/icons/refresh.svg";
import fooLi from "../assets/icons/fooLi.svg";
import fooIg from "../assets/icons/fooIg.svg";
import fooFb from "../assets/icons/fooFb.svg";
import tiktok from "../assets/icons/tiktok.svg";
import phone from "../assets/icons/phone.svg";
import emptyvault from "../assets/img/emptyvault.svg";
import emptyref from "../assets/img/emptyref.svg";
import emptytripconf from "../assets/img/emptytripconf.svg";
import emptytripreq from "../assets/img/emptytripreq.svg";
import emptywish from "../assets/img/emptywish.svg";
import emptyFilter from "../assets/img/emptyFilter.svg";
import emptyTransaction from "../assets/img/emptyTransaction.svg";
import loca from "../assets/icons/loca.svg";
import mail from "../assets/icons/mail.svg";
import fooTwit from "../assets/icons/fooTwit.svg";
import fooLogo from "../assets/img/fooLogo.svg";
import grid from "../assets/img/grid.svg";
import bg from "../assets/img/bg.png";
import canvas from "../assets/img/canvas.png";
import curve from "../assets/img/curve.png";
import persons from "../assets/img/persons.png";
import car from "../assets/img/car.svg";
import car1 from "../assets/img/car1.svg";
import car2 from "../assets/img/car2.svg";
import av1 from "../assets/img/av1.svg";
import av2 from "../assets/img/av2.svg";
import av3 from "../assets/img/av3.svg";
import av4 from "../assets/img/av4.svg";
import av5 from "../assets/img/av5.svg";
import av6 from "../assets/img/av6.svg";
import av7 from "../assets/img/av7.svg";
import av8 from "../assets/img/av8.svg";
import av9 from "../assets/img/av9.svg";
import line from "../assets/img/line.svg";
import zoom from "../assets/img/zoom.svg";
import girl from "../assets/img/girl.svg";
import boy from "../assets/img/boy.svg";
import world from "../assets/img/world.svg";
import cals from "../assets/img/cal.svg";
import plan from "../assets/img/plane.svg";
import smallsmall from "../assets/img/smallsmall.svg";
import smallSmallInt from "../assets/img/smallsmallint.svg";
import star from "../assets/img/star.svg";
import mask1 from "../assets/img/mask-1.svg";
import mobilemask1 from "../assets/img/mobilemask.svg";
import mask2 from "../assets/img/mask-2.png";
import mask3 from "../assets/img/mask-3.png";
import unit1 from "../assets/img/unit-1.svg";
import unit2 from "../assets/img/unit-2.svg";
import student from "../assets/img/student.svg";
import emoji from "../assets/img/emoji.svg";
import rev1 from "../assets/img/rev1.svg";
import reach from "../assets/img/reach.svg";
import question from "../assets/img/question.svg";
import people1 from "../assets/img/people-1.svg";
import people2 from "../assets/img/people-2.svg";
import people3 from "../assets/img/people-3.svg";
import arrows from "../assets/img/arrows.svg";
import plus from "../assets/img/plus.svg";
import minus from "../assets/img/minus.svg";
import wiresuccess from "../assets/img/wiresuccess.svg";
import refer from "../assets/img/refer.svg";
import notFound from "../assets/img/404.svg";
import offline from "../assets/img/offline.svg";
import logArrow from "../assets/img/log-arrow.svg";
import btnArrow from "../assets/img/btn-arrows.svg";
import spiral from "../assets/img/spiral.svg";
import masks1 from "../assets/img/mask-1.svg";
import ngPeople from "../assets/img/ng-people.svg";
import intPeople from "../assets/img/int-people.svg";
import btnSecArrow from "../assets/img/btn-sec-arrow.svg";
import bluePH from "../assets/img/bluePH.svg";
import redPH from "../assets/img/redPH.svg";
import camera from "../assets/galleryIcons/camera.svg";
import confetti from "../assets/galleryIcons/confetti.svg";
import flight from "../assets/galleryIcons/flight.svg";
import gift from "../assets/galleryIcons/gift.svg";
import house from "../assets/galleryIcons/house.svg";
import glass from "../assets/galleryIcons/magnifying-glass.svg";
import tree from "../assets/galleryIcons/tree.svg";
import trophy from "../assets/galleryIcons/trophy.svg";
import galleryBanner from "../assets/galleryIcons/gallery-banner.png";
import couponMain from "../assets/couponIcons/couponMain.svg";
import bigWhiteCoupon from "assets/couponIcons/bigWhiteCp.svg";
import bigBlueCoupon from "assets/couponIcons/bigBlueCp.svg";
import whiteBgCoupon from "../assets/couponIcons/whiteBgCp.svg";
import blueBgCoupon from "../assets/couponIcons/blueBgCp.svg";
import yellowTag from "../assets/couponIcons/yellowTag.svg";
import blueTag from "../assets/couponIcons/blueTag.svg";
import purpleTag from "../assets/couponIcons/purpleTag.svg";
import fly from "../assets/couponIcons/flight.svg";
import andrew from "../assets/img/andrew.png";
import ay from "../assets/couponIcons/AY.png";

export default {
  dubai,
  people3,
  btnSecArrow,
  wiresuccess,
  masks1,
  persons,
  reach,
  plus,
  ngPeople,
  emptyref,
  emptytripreq,
  emptytripconf,
  notFound,
  emptyvault,
  emptywish,
  emptyFilter,
  emptyTransaction,
  smallsmall,
  smallSmallInt,
  intPeople,
  minus,
  logArrow,
  btnArrow,
  refer,
  question,
  rev1,
  spiral,
  emoji,
  mask1,
  boy,
  people2,
  arrows,
  people1,
  mask2,
  student,
  mask3,
  unit1,
  unit2,
  car1,
  star,
  plan,
  world,
  cals,
  girl,
  car2,
  line,
  zoom,
  av1,
  av2,
  av3,
  av4,
  av5,
  av6,
  av7,
  av8,
  av9,
  loca,
  car,
  mail,
  curve,
  grid,
  bg,
  phone,
  refresh,
  avatar,
  smile,
  fooFb,
  fooIg,
  fooLi,
  fooLogo,
  fooTwit,
  tiktok,
  thumb,
  botpic,
  wave,
  pack,
  supp,
  acc,
  planes,
  visa,
  card,
  send,
  plane,
  bot,
  lock,
  loc,
  call,
  logout,
  copy,
  users,
  search,
  cal,
  undo,
  bars,
  checkMark,
  arrowRight,
  paris,
  newyork,
  cursor,
  london,
  like,
  trip,
  unlike,
  notFavorite,
  google,
  card1,
  card2,
  green,
  success,
  beach,
  bannerimg,
  arrow,
  check,
  bannerbg,
  man1,
  man2,
  lady,
  wavy,
  testimonialbg,
  whitebg,
  kikelomo,
  temidayo,
  zaana,
  ereretola,
  canvas,
  mobilemask1,
  bluePH,
  redPH,
  offline,
  camera,
  confetti,
  flight,
  gift,
  house,
  glass,
  tree,
  trophy,
  galleryBanner,
  couponMain,
  whiteBgCoupon,
  blueBgCoupon,
  bigWhiteCoupon,
  bigBlueCoupon,
  blueTag,
  yellowTag,
  purpleTag,
  fly,
  andrew,
  ay,
};
