import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { ImCancelCircle } from "react-icons/im";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { ArrowDownward, ArrowDownwardRounded } from "@material-ui/icons";
import { BiChevronDown } from "react-icons/bi";
import searchStore from "stores/searchStore";
import { Button } from "components/buttons";

export const Input = ({
  type,
  placeholder,
  Icon,
  withLabel,
  label,
  inputClass,
  onClick,
  name,
  value,
  onFocus,
  onChange,
  className,
  iconClass,
  labelClassName,
  subText,
  subTextClass,
  IconLeft,
  style,
}) => {
  return (
    <div
      className={`${inputClass}flex flex-col space-y-3 transition-all ${
        withLabel ? "flex flex-col justify-between" : ""
      }`}
    >
      {withLabel && (
        <span className={`${labelClassName} text-base font-medium  `}>
          {label}{" "}
        </span>
      )}

      <div className="relative">
        <input
          className={`${
            IconLeft ? "pl-8" : ""
          } w-full h-10 placeholder:text-gray-100 placeholder:text-base border rounded  focus:outline-none focus:border-blue-100 focus:ring-offset-blue-100 bg-white-100 text:base ${className}`}
          type={type}
          placeholder={placeholder}
          onClick={onClick}
          onChange={onChange}
          name={name}
          value={value}
          onFocus={onFocus}
          style={style}
        />
        <div className="absolute flex items-center top-[50%] -translate-y-1/2 gap-1 right-2 ">
          {Icon && <div className={`${iconClass} cursor-pointer `}>{Icon}</div>}
          {subText && (
            <div
              className={`${subTextClass} cursor-pointer text-gray-100 text-sm `}
            >
              {subText}
            </div>
          )}
        </div>
        <div className="absolute flex items-center top-[50%] -translate-y-1/2 gap-1 left-2 ">
          {IconLeft && (
            <div className={`${iconClass} cursor-pointer `}>{IconLeft}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export const Textarea = ({
  type,
  placeholder,
  Icon,
  withLabel,
  label,
  inputClass,
  onClick,
  name,
  value,
  onFocus,
  onChange,
  className,
  iconClass,
  labelClassName,
  subText,
  subTextClass,
  IconLeft,
}) => {
  return (
    <div className={`flex flex-col space-y-3 transition-all ${inputClass}`}>
      {withLabel && (
        <span className={`${labelClassName} text-base font-medium  `}>
          {label}{" "}
        </span>
      )}

      <div className="relative">
        <textarea
          className={`${
            IconLeft ? "pl-8" : ""
          } w-full h-10 text-gray-100 placeholder:text-gray-100 placeholder:text-base border rounded  focus:outline-none focus:border-blue-100 focus:ring-offset-blue-100 bg-white-100 text:base ${className}`}
          type={type}
          placeholder={placeholder}
          onClick={onClick}
          onChange={onChange}
          name={name}
          value={value}
          onFocus={onFocus}
        ></textarea>
        <div className="absolute flex items-center top-[10px] gap-1 right-2 z-[9]">
          {Icon && <div className={`${iconClass} cursor-pointer `}>{Icon}</div>}
          {subText && (
            <div
              className={`${subTextClass} cursor-pointer text-gray-100 text-sm `}
            >
              {subText}
            </div>
          )}
        </div>
        <div className="absolute flex items-center top-[50%] -translate-y-1/2 gap-1 left-2 z-[9]">
          {IconLeft && (
            <div className={`${iconClass} cursor-pointer `}>{IconLeft}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export const RangeInput = observer(
  ({
    type,
    placeholder,
    Icon,
    withLabel,
    label,
    inputClass,
    onClick,
    name,
    value,
    onFocus,
    onChange,
    className,
    iconClass,
    labelClassName,
    rangeClass,
    minValue,
    maxValue,
    minName,
    maxName,
  }) => {
    return (
      <div className={`flex flex-col space-y-2 transition-all ${inputClass}`}>
        {withLabel && (
          <span className={`${labelClassName} text-base font-medium  `}>
            {label}{" "}
          </span>
        )}
        <div className={` relative ${className}`}>
          <input
            className={`w-full bg-white-100 focus:outline-none focus:border-blue-100 text-sm text-gray-100  ${rangeClass} `}
            type={type}
            placeholder="Min Amount"
            onClick={onClick}
            onChange={onChange}
            name={minName}
            value={minValue}
            onFocus={onFocus}
          />

          <input
            className={`w-full bg-white-100 focus:outline-none focus:border-blue-100 text-sm text-gray-100 ${rangeClass}`}
            type={type}
            placeholder="Max Amount"
            onClick={onClick}
            onChange={onChange}
            name={maxName}
            value={maxValue}
            onFocus={onFocus}
          />

          {Icon && <div className={`absolute  ${iconClass}`}>{Icon}</div>}
        </div>
      </div>
    );
  }
);

export const SelectWithInput = ({
  type,
  placeholder,
  Icon,
  IconLeft,
  withLabel,
  label,
  title,
  inputClass,
  onClick,
  name,
  value,
  minValue,
  maxValue,
  onFocus,
  onChange,
  className,
  iconClass,
  labelClassName,
  options,
  required,
  disabled,
}) => {
  return (
    <div
      className={`${
        withLabel ? "flex flex-col justify-between" : ""
      } flex flex-col space-y-2 relative transtion-all  ${inputClass}`}
    >
      {withLabel && (
        <span className={`${labelClassName} text-base pb-2 font-medium `}>
          {title}
        </span>
      )}

      <div className="flex relative">
        <div
          className={`${iconClass} ${
            IconLeft
              ? "flex rounded-l w-fit max-w-[25px] sm:max-w-[30px] justify-center items-center border-r h-full bg-white-100 focus:border-blue-100 pl-2"
              : "hidden"
          }`}
        >
          {IconLeft}
        </div>
        <select
          className={`${className} ${
            IconLeft ? "rounded-r border-r" : "border rounded"
          } w-full h-8 text-gray-100 placeholder:text-gray-100 placeholder:text-sm focus:outline-none focus:border-blue-100  focus:ring-offset-blue-10000 bg-white-100 cursor-pointer relative`}
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          disabled={disabled}
        >
          <option
            value=""
            className="text-gray-100"
            label={placeholder}
            disabled
          >
            {placeholder}
          </option>
          {options &&
            options.length > 0 &&
            options.map((option) => {
              const value = option?.value ? option.value : option;
              const label = option?.label ? option.label : option;
              return (
                <option
                  className="text-black-100 h-16"
                  key={`${value}-${label}`}
                  value={value}
                  label={label}
                >
                  {label}
                </option>
              );
            })}
        </select>
        {Icon && (
          <div
            className={`absolute flex items-center top-[50%] -translate-y-1/2 gap-1 right-2 ${iconClass}`}
          >
            {Icon}
          </div>
        )}
      </div>
    </div>
  );
};

export const CustomDropdown = observer(
  ({
    type,
    placeholder,
    Icon,
    withLabel,
    label,
    title,
    inputClass,
    onClick,
    name,
    value,
    onFocus,
    onChange,
    className,
    iconClass,
    labelClassName,
    options,
    required,
    disabled,
    toggleTravel,
    toggleTravelModal,
    addAdults,
    addChildren,
    addInfants,
    subtractAdults,
    subtractChildren,
    subtractInfants,
    searchTrip,
    total_travellers,
    adults,
    children,
    infants,
    modalClass,
    coverClass,
    smallScreen,
    landingPage,
  }) => {
    return (
      <div
        className={`${inputClass} z-[40] flex flex-col transition-all relative`}
      >
        <div
          onClick={toggleTravelModal}
          className={`${
            withLabel ? "flex flex-col justify-between min-h-[80px] h-full" : ""
          }`}
        >
          {withLabel && (
            <span className={`${labelClassName} text-base font-medium`}>
              {label}{" "}
            </span>
          )}

          <div className="relative">
            <p
              className={`${className} w-full text-gray-100 placeholder:text-gray-100 placeholder:text-base border rounded  focus:outline-none focus:border-blue-10000 focus:ring-offset-blue-10000 bg-white-100 text:base `}
              onFocus={onFocus}
            >
              {total_travellers
                ? `Total travellers: ${total_travellers}`
                : placeholder}
            </p>
            {Icon && <div className={`${iconClass} absolute   `}>{Icon}</div>}
          </div>
        </div>

        {toggleTravel && (
          <div
            className={`absolute w-[150%] h-[100%] right-0 top-3/4 grid z-[900] place-content-center `}
          >
            <div className="flex justify-center items-center">
              <div className="absolute shadow-btn rounded-[8px] p-5 bg-[#fff] w-[100%] top-[30%] z-[555]">
                <div className="flex mb-[40px] items-center justify-between">
                  <div>
                    <div className="text-[#06202C] font-medium mb-[4px]">
                      Adults
                    </div>
                    <div className="text-[#5D6D8F] text-[14px]">12+ years</div>
                  </div>

                  <div className="flex items-center gap-[10px]">
                    <div
                      onClick={subtractAdults}
                      className="bg-[#EAECF0] flex flex-col justify-center items-center h-[40px] w-[40px] cursor-pointer rounded-[8px]"
                    >
                      -
                    </div>
                    <div className="font-bold  flex flex-col justify-center items-center h-[20px] w-[20px]">
                      {adults}
                    </div>
                    <div
                      onClick={addAdults}
                      className="bg-[#D2ECF8] cursor-pointer flex flex-col justify-center items-center h-[40px] w-[40px] rounded-[8px]"
                    >
                      +
                    </div>
                  </div>
                </div>

                <div className="flex mb-[40px] items-center justify-between">
                  <div>
                    <div className="text-[#06202C] font-medium mb-[4px]">
                      Children
                    </div>
                    <div className="text-[#5D6D8F] text-[14px]">
                      2 - 12 years
                    </div>
                  </div>

                  <div className="flex items-center gap-[10px]">
                    <div
                      onClick={subtractChildren}
                      className="bg-[#EAECF0] cursor-pointer flex flex-col justify-center items-center h-[40px] w-[40px]  rounded-[8px]"
                    >
                      -
                    </div>
                    <div className="font-bold flex flex-col justify-center items-center h-[20px] w-[20px]">
                      {children}
                    </div>
                    <div
                      onClick={addChildren}
                      className="bg-[#D2ECF8] cursor-pointer flex flex-col justify-center items-center h-[40px] w-[40px] rounded-[8px]"
                    >
                      +
                    </div>
                  </div>
                </div>

                <div className="flex mb-[40px] items-center justify-between">
                  <div>
                    <div className="text-[#06202C] font-medium mb-[4px]">
                      Infants
                    </div>
                    <div className="text-[#5D6D8F] text-[14px]">
                      Under 2 years
                    </div>
                  </div>

                  <div className="flex items-center gap-[10px]">
                    <div
                      onClick={subtractInfants}
                      className="bg-[#EAECF0] cursor-pointer flex flex-col justify-center items-center h-[40px] w-[40px]  rounded-[8px]"
                    >
                      -
                    </div>
                    <div className="font-bold flex flex-col justify-center items-center h-[20px] w-[20px]">
                      {infants}
                    </div>
                    <div
                      onClick={addInfants}
                      className="bg-[#D2ECF8] cursor-pointer flex flex-col justify-center items-center h-[40px] w-[40px] rounded-[8px]"
                    >
                      +
                    </div>
                  </div>
                </div>
                <div className="flex justify-end items-end">
                  <Button
                    onClick={toggleTravelModal}
                    className="w-[155px]"
                    isBlue
                    text="Apply Filter"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);
export const CategoryDropdown = observer(
  ({
    Icon,
    withLabel,
    label,

    inputClass,

    onFocus,

    className,
    iconClass,
    labelClassName,

    toggleTravelModal,

    toggleCategories,
    selectedCategories,
    allCategories,
    addCategory,
    toggleCategoryModal,
    categorySearch,
  }) => {
    const pickedCategories = selectedCategories.map((item) => item.name);
    return (
      <div
        className={`${inputClass}  z-[40] flex flex-col transition-all relative`}
      >
        <div
          onClick={toggleCategoryModal}
          className={`${
            withLabel ? "flex flex-col justify-between min-h-[80px] h-full" : ""
          }`}
        >
          {withLabel && (
            <span className={`${labelClassName} text-base font-medium`}>
              {label}{" "}
            </span>
          )}

          <div className="relative">
            <p
              className={`${className} overflow-x-hidden text-ellipsis whitespace-nowrap  w-full text-gray-100 placeholder:text-gray-100 placeholder:text-base border rounded  focus:outline-none focus:border-blue-10000 focus:ring-offset-blue-10000 bg-white-100 text:base `}
              onFocus={onFocus}
            >
              {selectedCategories.length === 0
                ? "Select Categories"
                : pickedCategories.join(", ")}
            </p>
            {Icon && <div className={`${iconClass} absolute   `}>{Icon}</div>}
          </div>
        </div>

        {toggleCategories && (
          <div
            className={`absolute w-[150%] h-[100%] right-0 top-3/4 grid z-[900] place-content-center `}
          >
            <div className="flex justify-center items-center">
              <div className="absolute shadow-btn rounded-[8px] p-5 bg-[#fff] w-[100%] top-[30%] z-[555]">
                <div className="h-[10em] overflow-y-scroll">
                  {allCategories.map((item) => (
                    <div
                      onClick={() => addCategory(item)}
                      className={`cursor-pointer py-1 px-1 my-[1px] ${
                        pickedCategories.includes(item.name)
                          ? "bg-blue-100/20 "
                          : ""
                      }`}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>

                <div className="flex justify-end items-end">
                  <Button
                    onClick={categorySearch}
                    className="w-[155px]"
                    isBlue
                    text="Apply Filter"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export const DateInput = () => {
  return <div>DateInput</div>;
};

export const CustomSelect = ({ label, setShow, show, options }) => {
  return (
    <div className="relative">
      <div
        onClick={() => setShow((prev) => !prev)}
        className="flex justify-between text-[14px] items-center border rounded-[8px] border-[#e0e0e0] h-[50px] p-[15px] gap-[12px]"
      >
        {label}
        <BiChevronDown />
      </div>

      {show && (
        <div className="bg-[white] absolute shadow-btn rounded-[8px] p-[20px] w-full">
          {options?.map((data, i) => (
            <div key={i} className="mb-[10px]">
              {data}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
