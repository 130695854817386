import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { STRIPE_PAYMENT } from "GraphQL/Mutations";
import { useMutation } from "@apollo/client";
import { errorToast, warningToast } from "components/toast/toast";

function Payment({
  amount,
  email,
  phoneNumber,
  name,
  paymentDetails,
  id,
  setFundsConfirmed,
}) {
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [fetching, setFetching] = useState(false);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const [stripePaymentIntent] = useMutation(STRIPE_PAYMENT);

  useEffect(() => {
    setFetching(true);
    stripePaymentIntent({
      variables: {
        userId: id,
        amount: parseInt(amount),
        initiated_by: "USER",
        paymentDetails: paymentDetails,
      },
    })
      .then((response) => {
        if (
          response.data.stripePaymentIntent.__typename ===
          "PaymentIntentSuccess"
        ) {
          setClientSecret(response.data.stripePaymentIntent?.clientSecret);
        }
        if (response.data.stripePaymentIntent.__typename === "BadRequest") {
          warningToast("Warning", response.data.stripePaymentIntent.message);
        }
      })
      .catch((error) => {
        errorToast("Error", error ? error?.message : "An error occured");
      })
      .finally(() => {
        setFetching(false);
      });
  }, []);

  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm setFundsConfirmed={setFundsConfirmed} />
        </Elements>
      )}
    </>
  );
}

export default Payment;
