import React, { Fragment, useEffect, useState } from "react";
import { ReactComponent as Logo } from "assets/img/logo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "components/buttons";
import Toast from "components/toast/toast";
import { images } from "constants";
import { MdMenu } from "react-icons/md";
import Drawer from "react-modern-drawer";
import { Close } from "@material-ui/icons";
import { Link } from "react-scroll";

const LandingPageHeader = ({ shadowed }) => {
  const navigate = useNavigate();

  const [scroll, setScroll] = useState(false);
  const [view, setView] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 20);
    });
  }, []);

  const [showMenu, setShowMenu] = useState(false);

  const landingLinks = [
    {
      name: "What we offer",
      id: "we-offer",
    },
    { name: "How it works", id: "how-it-works" },
    { name: "Exclusive offers", id: "ex-offers" },
  ];

  return (
    <Fragment>
      <header
        style={{
          boxShadow: scroll ? "0px 4px 48px rgba(226, 226, 226, 0.1)" : "",
        }}
        className={`absolute w-full bg-[#fff] z-[1000] font-[Satoshi] py-5 px-4 lg:px-12 ${
          shadowed && "shadow-header drop-shadow-lg"
        }`}
      >
        <div className="flex space-x-2 items-center justify-between">
          <Toast />
          <div className="w-[40%] ">
            <NavLink to={"/"}>
              <Logo className="w-24 sm:w-32" />
            </NavLink>
          </div>

          <div className="xs:hidden lg:flex text-[#093549] w-full font-medium justify-between items-center">
            {landingLinks.map((i) => {
              return (
                <Link
                  onClick={() => {
                    setView("we-offer");
                  }}
                  className="cursor-pointer pr-5 hover:border-b-2 hover:border-draw-animation "
                  to={i.id}
                  smooth={true}
                  duration={500}
                  offset={-50}
                >
                  <p>{i.name}</p>
                </Link>
              );
            })}

            <NavLink onClick={() => setShowMenu(false)} to={"/gallery"}>
              <p className="cursor-pointer pr-5 hover:border-b-2 hover:border-draw-animation ">
                Gallery
              </p>
            </NavLink>
          </div>

          <div className="xs:flex lg:hidden flex justify-end w-full">
            <MdMenu onClick={() => setShowMenu(true)} size="32px" />
          </div>

          <Drawer
            open={showMenu}
            onClose={() => setShowMenu(false)}
            direction="right"
            style={{ width: "100%", padding: "20px", maxWidth: "250px" }}
            lockBackgroundScroll={true}
          >
            <Close onClick={() => setShowMenu(false)} />

            <div className="flex flex-col text-[#093549] w-full font-medium gap-[36px] items-center">
              {landingLinks.map((i) => {
                return (
                  <Link
                    onClick={() => {
                      setView("we-offer");
                    }}
                    className="cursor-pointer pr-5 hover:border-b-2 hover:border-draw-animation "
                    to={i.id}
                    smooth={true}
                    duration={500}
                    offset={-50}
                  >
                    <p>{i.name}</p>
                  </Link>
                );
              })}
              <NavLink to={"/gallery"}>
                <p className="cursor-pointer pr-5 hover:border-b-2 hover:border-draw-animation">
                  Gallery
                </p>
              </NavLink>
            </div>

            <div className="mt-[150px] flex w-[100%] items-center gap-[16px]">
              <Button
                withIcon
                icon={<img src={images.logArrow} />}
                onClick={() => {
                  setShowMenu(false);
                  navigate("/auth/login");
                }}
                className="border gap-[13px] flex flex-row-reverse items-center justify-center text-[14px] rounded-lg border-blue-100 h-12 hover:bg-[#d2ecf8] hover:border-[2px] font-medium w-full"
                text="Login"
              />

              <Button
                onClick={() => {
                  setShowMenu(false);
                  navigate("/auth/signup");
                }}
                text="Sign Up"
                isBlue
                className="font-medium hover:bg-[#1784b6] transition duration-500 ease-in-out transform hover:scale-105 h-12 w-full text-[14px]"
              />
            </div>
          </Drawer>

          <div className="xs:hidden lg:flex w-[50%] items-center justify-end gap-[24px]">
            <Button
              withIcon
              icon={<img src={images.logArrow} />}
              onClick={() => navigate("/auth/login")}
              className="border gap-[13px] flex flex-row-reverse items-center justify-center text-[14px] rounded-lg border-blue-100 h-12 hover:bg-[#d2ecf8] hover:border-[2px] font-medium w-[129px]"
              text="Login"
            />

            <Button
              onClick={() => navigate("/auth/signup")}
              text="Sign Up"
              isBlue
              className="font-medium hover:bg-[#1784b6] transition duration-500 ease-in-out transform hover:scale-105 h-12 w-[129px] text-[14px]"
            />
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default LandingPageHeader;
