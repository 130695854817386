import React from "react";
import { ReactComponent as MasterCard } from "assets/img/mastercard.svg";
import { ReactComponent as VerveCard } from "assets/img/vervecard.svg";
import { ReactComponent as VisaCard } from "assets/img/visacard.svg";
import { AiOutlineCheck } from "react-icons/ai";

const AtmCard = ({
  card_name,
  bank,
  last_digit,
  expires,
  selected,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={`${
        selected ? "border-[#219653] " : "border-[#1212121F]"
      } max-w-[177px] bg-[#FAF9FF] h-[156px] border rounded-[8px] relative hover:cursor-pointer overflow-hidden`}
    >
      {selected && (
        <div className="absolute right-0 top-0">
          <div className="flex items-center justify-center bg-[#219653] w-6 aspect-square rounded-tr-[8px] rounded-bl-[8px] ">
            <AiOutlineCheck color="#ffffff" width={"8.32px"} />
          </div>
        </div>
      )}
      <div className="px-[15px] pt-4 pb-[10px] flex flex-col h-full gap-y-2">
        <div>
          {card_name === "MASTERCARD" ? (
            <MasterCard className="h-10 w-10" />
          ) : card_name === "VERVE" ? (
            <VerveCard className="h-10 w-10" />
          ) : card_name === "VISA" ? (
            <VisaCard className="h-10 w-10" />
          ) : (
            <MasterCard className="h-10 w-10" />
          )}
        </div>

        <div className="flex flex-col">
          <p className="text-[#06202C] text-[14px] font-bold">{bank}</p>
          <p className="text-[#98A2B3]/60 text-[12px] font-medium">
            (XXXX {last_digit})
          </p>
        </div>

        <div className="text-[#475467]/80 font-medium text-[12px]">
          Expires {expires}
        </div>
      </div>
    </div>
  );
};

export default AtmCard;
