import React, { Fragment, useState } from "react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { useQuery } from "@apollo/client";

import { Button } from "../components/buttons";
import { LOAD_SAVINGSACCOUNT, LOAD_WALLET } from "GraphQL/Queries";

export default function FlutterWavePayment({
  isBlue,
  text,
  title,
  isGreen,
  description,
  logo,
  isYellow,
  isRed,
  phoneNumber,
  email,
  firstName,
  lastName,
  meta,
  amount,
  setFundsConfirmed,
  disabled,
  className,
  icon,
  withIcon,
}) {
  const { refetch: refetchSavingsAccount } = useQuery(LOAD_SAVINGSACCOUNT);
  const { refetch: refetchWallet } = useQuery(LOAD_WALLET);

  const handleRefetch = () => {
    refetchSavingsAccount();
    refetchWallet();
  };

  const [flwloading, setFlwLoading] = useState(false);
  const config = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_TEST_KEY,
    tx_ref: Date.now(),
    amount: amount,
    currency: "NGN",
    initiated_by: "USER",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: email,
      phonenumber: phoneNumber,
      name: firstName + "" + lastName,
    },
    customizations: {
      title: title,
      description: description,
      logo: logo,
    },
    meta,
  };

  const handleFlutterPayment = useFlutterwave(config);

  const handleFlutterwavePayment = () => {
    setFlwLoading(true);
    handleFlutterPayment({
      callback: (response) => {
        setFundsConfirmed({ status: true, amount: response.amount });
        handleRefetch();
        closePaymentModal();

        // this will close the modal programmatically
      },
      onClose: () => {
        setFlwLoading(false);
      },
    });
  };
  return (
    <Fragment>
      <Button
        className={className}
        withIcon={withIcon}
        icon={icon}
        isBlue={isBlue}
        isYellow={isYellow}
        isRed={isRed}
        isGreen={isGreen}
        onClick={handleFlutterwavePayment}
        text={text}
        loading={flwloading}
        disabled={disabled || flwloading}
      />
    </Fragment>
  );
}
