import React from "react";
import { dropdown } from "components/items";
import { images } from "constants";
import { Link } from "react-router-dom";

const ProfileModal = ({ handleProfileSetings, logout }) => {
  return (
    <div className="rounded-[8px] shadow-btn  bg-blue-50 pb-[10px] pt-[15px] fixed right-2 top-16 w-auto ">
      <ul className="flex flex-col">
        {dropdown.map((data) =>
          data.route ? (
            <Link to={data.route} key={data.id}>
              <li
                className="cursor-pointer mx-[20px] hover:bg-[#E5F4FB] hover:text-blue-100 hover:font-medium hover:rounded-[8px] transition delay-75 duration-300 ease-in-out p-[12px]"
                onClick={handleProfileSetings}
              >
                {data.title}
              </li>
            </Link>
          ) : (
            <li
              className="text-red-100 mt-[10px] px-[30px] border-t border-[#e0e0e0] text-[15px] p-[12px] cursor-pointer mdLg:block"
              onClick={logout}
            >
              <div className="flex items-center gap-[16px]">
                <img src={images.logout} />
                <div>Logout</div>
              </div>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default ProfileModal;
