/**
 * Advanced example demonstrating all core MobX constructs.
 */
import { makeAutoObservable } from "mobx";

class SearchStore {
  // ====================================================
  // State
  // ====================================================
  user = null;
  count = 0;
  error = null;
  loading = false;

  searchLoading = false;
  filteredListing = [];

  travellersModal = false;
  countries = [];
  searchParameters = {
    destination: "",
    departure: null,
    return: null,
    rating: "",
    travellers: {
      adults: 0,
      children: 0,
      infants: 0,
    },
    total_travellers: 0,
    budget: {
      minValue: null,
      maxValue: null,
    },
    categories: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  // ====================================================
  // Computed views

  // ====================================================
  // While MobX promotes OOP, we can still benefit from using FP where it's appropriate

  // ====================================================
  // Actions
  // ====================================================

  toggleFilter = () => {
    this.filter = !this.filter;
  };

  addTravellers = (type) => {
    this.searchParameters.total_travellers =
      this.searchParameters.total_travellers + 1;
    this.searchParameters.travellers[type] =
      this.searchParameters.travellers[type] + 1;
  };
  subtractTravellers = (type) => {
    if (this.searchParameters.travellers[type] > 0) {
      this.searchParameters.travellers[type] =
        this.searchParameters.travellers[type] - 1;

      if (this.searchParameters.total_travellers > 0) {
        this.searchParameters.total_travellers =
          this.searchParameters.total_travellers - 1;
      } else {
        this.searchParameters.total_travellers = 0;
      }
    } else {
      this.searchParameters.travellers[type] = 0;
    }
  };

  refreshSearch = () => {
    this.searchParameters = {
      destination: "",
      departure: null,
      return: null,
      rating: "",
      travellers: {
        adults: 0,
        children: 0,
        infants: 0,
      },
      total_travellers: 0,
      budget: {
        minValue: "",
        maxValue: "",
      },
      categories: [],
    };
  };

  updateSearch = () => {
    this.searchLoading = !this.searchLoading;
  };

  toggleTravellers = () => {
    this.travellersModal = !this.travellersModal;
  };

  setCountriesFilter = (array) => {
    this.countries = array;
  };
}

export default new SearchStore();
