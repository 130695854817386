export const Warn = ({ fill }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 22.812C6.977 22.812 2.5 18.335 2.5 12.812C2.5 7.28901 6.977 2.81201 12.5 2.81201C18.023 2.81201 22.5 7.28901 22.5 12.812C22.5 18.335 18.023 22.812 12.5 22.812ZM12.5 20.812C14.6217 20.812 16.6566 19.9692 18.1569 18.4689C19.6571 16.9686 20.5 14.9337 20.5 12.812C20.5 10.6903 19.6571 8.65545 18.1569 7.15516C16.6566 5.65487 14.6217 4.81201 12.5 4.81201C10.3783 4.81201 8.34344 5.65487 6.84315 7.15516C5.34285 8.65545 4.5 10.6903 4.5 12.812C4.5 14.9337 5.34285 16.9686 6.84315 18.4689C8.34344 19.9692 10.3783 20.812 12.5 20.812ZM11.5 7.81201H13.5V9.81201H11.5V7.81201ZM11.5 11.812H13.5V17.812H11.5V11.812Z"
        fill={fill}
      />
    </svg>
  );
};
