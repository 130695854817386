import React from "react";
import { ReactComponent as Close } from "../../assets/img/close-modal.svg";
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";

const SocialMedia = ({ onClose, details }) => {
  const shareTitle = "Travelbay Signup\n\n";
  const urlDescription =
    "Most Convenient Way To Experience The World!\nSave to travel.\n";
  const codeDescription =
    "Most Convenient Way To Experience The World!\nSave to travel\nSign Up using the referral code\n";
  return (
    <div className="absolute rounded-[8px] w-[100%] h-[100%] top-0 left-0 z-[1000] grid place-content-center">
      <div onClick={onClose} />
      <div className="bg-[#fff] relative pt-[20px] p-[40px]">
        <div
          onClick={onClose}
          className="cursor-pointer flex justify-end items-end "
        >
          <Close />
        </div>
        <div className="font-bold text-[#093549] text-[18px]">
          Social Media Share
        </div>
        <div className="mt-[20px] flex items-center gap-[20px]">
          <WhatsappShareButton
            url={details?.url ? details?.url : details?.code}
            title={details?.title ? `${details?.title}\n\n` : shareTitle}
            separator={
              details?.description
                ? `${details?.description}\n`
                : codeDescription
            }
          >
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
          <FacebookShareButton
            url={details?.url ? details?.url : details?.code}
          >
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <TwitterShareButton
            url={details?.url ? details?.url : details?.code}
            title={details?.title ? `${details?.title}\n\n` : shareTitle}
          >
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
          <LinkedinShareButton
            url={details?.url ? details?.url : details?.code}
            title={details?.title ? `${details?.title}\n\n` : shareTitle}
            summary={
              details?.description
                ? `${details?.description}\n`
                : urlDescription
            }
          >
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
          <TelegramShareButton
            url={details?.url ? details?.url : details?.code}
            title={details?.title ? `${details?.title}\n\n` : shareTitle}
          >
            <TelegramIcon size={32} round={true} />
          </TelegramShareButton>
        </div>
      </div>{" "}
    </div>
  );
};

export default SocialMedia;
