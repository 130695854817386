const ModalType = {
  PERSONAL: "Personal",
  JOINT: "Joint",
  ADDFUNDS_VAULT: "AddFundsToVault",
  ADDFUNDS_WALLET: "AddFundsToWallet",
  PLANTRIP: "PlanTrip",
  CREATEVAULT: "CreateVault",
  FINDAPACKAGE: "FindAPackage",
  ADDTRIPVAULT: "AddTripVault",
  ADD_EARNING: "AddEarning",
  ADD_COUPON: "AddCoupon",
  SOCIAL_MEDIA: "SocialMedia",
  AUTOSAVE: "Autosave",
  TRIPVAULT: "TripVault",
  PROFILE: "Profile",
  TRIP_SUCCESS: "TripSuccess",
  CUSTOM_TRIP_REQUEST: "CustomTripRequest",
};

export { ModalType };
