import React, { useEffect, useState } from "react";
import { images } from "constants";
import TripSuccessModal from "components/modal/TripSuccessModal";

const Frame2 = ({ setInitDone }) => {
  const [isProceed, setIsProceed] = useState(false);
  const [isTrip, setIsTrip] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsProceed(true);
    }, 4600);
    setTimeout(() => {
      setIsTrip(true);
      setIsProceed(false);
    }, 8500);
  }, []);

  useEffect(() => {
    if (isTrip) {
      setTimeout(() => {
        setShowModal(true);
        setIsTrip(false);
        setIsProceed(false);
      }, 3000);
    }
  }, [isTrip]);

  useEffect(() => {
    if (showModal) {
      setIsTrip(false);
      setIsProceed(false);
      setTimeout(() => {
        setShowModal(false);
        setInitDone(true);
      }, 5000);
    }
  }, [showModal]);


  return (
    <div className="relative z-30 flex flex-col justify-center lg:w-[82%] xs:w-full sm:w-auto xs:my-[20px] lg:my-[40px] items-center">
       <div className="absolute z-[1001] w-[100%] h-[50%] lg:top-0 xs:bottom-0 left-0 grid place-content-center overlay" />
      {isTrip && (
        <div className="absolute rounded-[8px] w-[100%] h-[100%] top-0 left-0 z-[1000] grid place-content-center gradient" />
      )}
      <div className="blue_bg relative text-[white] h-[420px] rounded-[8px] px-[24px] py-[30px]">
        <div>
          <div>
            <div className="text-[17px] font-bold">Plan your trip</div>
            <div className="text-[13px] font-medium text-light">
              Tell us a bit about your dream trip
            </div>
          </div>

          <div className="flex mt-[15px] items-center gap-[16px]">
            <div className="flex gap-[8px] items-center">
              <div className="bg-[#136A91] flex justify-center items-center rounded-full h-[24px] w-[24px]">
                1
              </div>
              <div className="font-bold">Flight details</div>
            </div>
            {isTrip && (
              <div className="stage">
                <div class="load" />
              </div>
            )}

            <div className="flex gap-[8px] items-center">
              <div
                className={`${
                  isProceed ? "bg-[#136A91]" : "bg-[#D2ECF8] text-[#1C9FDA]"
                } flex justify-center items-center rounded-full h-[24px] w-[24px]`}
              >
                2
              </div>
              <div className="font-bold">Trip preference</div>
            </div>
          </div>
        </div>
        {!isTrip && !showModal && (
          <div className={`cursor ${isProceed ? "cursor3" : "cursor4"}`}>
            <img src={images.cursor} />
          </div>
        )}
        <div className="relative flex mt-[20px]">
          <img src={images.trip} alt="" />
        </div>

        <button className="flex bg-[#FEBB02] w-full h-[48px] rounded-[8px] items-center justify-center font-bold mt-[30px] text-[#093549] gap-[10px]">
          {isProceed ? "Generate trip estimate" : "Proceed to trip preference"}
          <img src={images.arrowRight} />
        </button>
        <TripSuccessModal open={showModal} />
      </div>
    </div>
  );
};

export default Frame2;
