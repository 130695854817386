import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { Button } from "../components/buttons";
import { Input, SelectInput } from "../components/inputs";
import {
  AUTOSAVE_UPDATE_MUTATION,
  FREQUENCY_UPDATE_MUTATION,
  SELECT_PRIMARY_CARD_MUTATION,
} from "GraphQL/Mutations";
import { useMutation } from "@apollo/client";
import { ModalType } from "constants/modalType";
import { ReactComponent as AddMoney } from "assets/img/add-funds.svg";
import AppSwitch from "components/buttons/switch";
import { ReactComponent as MasterCard } from "assets/img/mastercard.svg";
import { ReactComponent as VerveCard } from "assets/img/vervecard.svg";
import { ReactComponent as VisaCard } from "assets/img/visacard.svg";
import { ReactComponent as Close } from "assets/img/close-modal.svg";
import Fail from "assets/icons/fail.png";
import { errorToast, successToast, warningToast } from "components/toast/toast";
import IconWithText from "components/header/IconWithText";
import { LOAD_SAVINGSACCOUNT } from "GraphQL/Queries";
import { AiOutlinePlus } from "react-icons/ai";
import AtmCard from "components/card/AtmCard";

const Autosave = ({
  autosaveState,
  exactTrip,
  handleClick,
  loadCardDetails,
  setModalType,
}) => {
  const resolvers = {
    AuthType: {
      DAILY: "DAILY",
      WEEKLY: "WEEKLY",
      MONTHLY: "MONTHLY",
      CUSTOM: "CUSTOM",
    },
  };
  const savingFrequencies = [
    { name: resolvers.AuthType.DAILY, days: "(1 Day)" },
    { name: resolvers.AuthType.WEEKLY, days: "(7 Days)" },
    { name: resolvers.AuthType.MONTHLY, days: "(30 Days)" },
  ];
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    savingFrequencies: exactTrip.trip.savings_frequency,
    Amount: " ",
  });
  const [autoSaveBtn, setAutoSaveBtn] = useState(autosaveState);
  const [primaryCard, setPrimaryCard] = useState({
    trip_vault_id: exactTrip.trip.id,
    primary_card_id: exactTrip.trip.primary_card
      ? exactTrip.trip.primary_card.id
      : null,
  });

  const [updateTripVaultAutosave] = useMutation(AUTOSAVE_UPDATE_MUTATION, {
    refetchQueries: [{ query: LOAD_SAVINGSACCOUNT }],
  });
  const [updateTripVaultSavingsFrequency] = useMutation(
    FREQUENCY_UPDATE_MUTATION,
    {
      refetchQueries: [{ query: LOAD_SAVINGSACCOUNT }],
    }
  );
  const [updatePrimaryCard] = useMutation(SELECT_PRIMARY_CARD_MUTATION, {
    refetchQueries: [{ query: LOAD_SAVINGSACCOUNT }],
  });

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }
  function handleRadioChange(id) {
    setPrimaryCard((prevFormData) => {
      return {
        ...prevFormData,
        primary_card_id: id,
      };
    });
  }
  let step_1 = true;
  let step_2 = true;
  let step_3 = true;
  const submitData = async () => {
    setSubmitting(true);

    if (autoSaveBtn !== autosaveState) {
      await updateTripVaultAutosave({
        variables: {
          trip_vault_id: exactTrip.trip.id,
          autosave: autoSaveBtn,
          primary_card: primaryCard.primary_card_id,
        },
      })
        .then((response) => {
          if (
            response.data.updateTripVaultAutosave.__typename ===
            "UpdateTripVaultAutosaveSuccess"
          ) {
            step_1 = true;
            successToast("Success", "Autosave state successfully updated");
          }
          if (
            response.data.updateTripVaultAutosave.__typename === "BadRequest"
          ) {
            step_1 = false;
            warningToast(
              "Warning",
              response.data.updateTripVaultAutosave.message
            );
          }
        })
        .catch((error) => {
          step_1 = false;
          if (error?.response?.status === 400) {
            errorToast("Error", "Bad request");
          } else {
            errorToast(
              "Error",
              error ? error?.message : "An error occured in request"
            );
          }
        });
    }

    if (formData.savingFrequencies !== exactTrip.trip.savings_frequency) {
      await updateTripVaultSavingsFrequency({
        variables: {
          trip_vault_id: exactTrip.trip.id,
          savings_frequency: formData.savingFrequencies,
        },
      })
        .then((response) => {
          if (response.data.updateTripVaultSavingsFrequency.name) {
            successToast("Success", "Savings frequency updated");
            step_2 = true;
          }
        })
        .catch((error) => {
          step_2 = false;
          if (error?.response?.status === 400) {
            errorToast("Error", "Bad request");
          } else {
            errorToast(
              "Error",
              error ? error?.message : "An error occured in request"
            );
          }
        });
    }
    if (loadCardDetails?.length > 0) {
      if (primaryCard?.primary_card_id !== exactTrip?.trip?.primary_card.id) {
        await updatePrimaryCard({
          variables: {
            trip_vault_id: exactTrip.trip.id,
            primary_card: primaryCard.primary_card_id,
          },
        })
          .then((response) => {
            if (response.data.updateTripVaultPrimaryCard.name) {
              successToast("Success", "Primary card updated");
              step_3 = true;
            }
          })
          .catch((error) => {
            step_3 = false;
            if (error?.response?.status === 400) {
              errorToast("Error", "Bad request");
            } else {
              errorToast(
                "Error",
                error ? error?.message : "An error occured in request"
              );
            }
          });
      }
    }
    setSubmitting(false);
  };

  async function mainCall() {
    await submitData();

    if (step_1 && step_2 && step_3) {
      successToast(
        "Success",
        `${exactTrip?.trip.name} vault details are updated`
      );
      handleClick();
    } else {
      errorToast("Error", "Error in Autosave update");
    }
  }

  return (
    <div className=" ">
      <div onClick={handleClick}></div>
      <div className="py-4">
        <div className="flex justify-between">
          <span className="text-blue-200/90 font-bold text-[24px]">
            Autosave
          </span>
          <span onClick={handleClick} className="cursor-pointer ">
            <Close />
          </span>
        </div>

        <div className=" flex flex-col space-y-8 py-8 overflow-y-auto modal_scroll max-h-[calc(100vh_-_180px)]">
          <div className="flex flex-col space-y-4 ">
            <SelectInput
              label="Select a savings frequency"
              placeholder={"_Select Savings frequency_"}
              withLabel
              labelClass={"text-blue-200/90 font-medium"}
              name={"savingFrequencies"}
              onChange={handleChange}
              options={savingFrequencies}
              value={formData.savingFrequencies}
              required
            />

            <div className="flex py-2 space-y-2 flex-col">
              <span className="font-medium text-blue-200/90 ">Saved Cards</span>
              <div className=" flex flex-wrap gap-2 justify-start w-[100%] ">
                {loadCardDetails?.length > 0 ? (
                  loadCardDetails?.map((cardDetail) => {
                    return (
                      <div>
                        <AtmCard
                          card_name={cardDetail?.card_name}
                          bank={cardDetail?.bank}
                          last_digit={cardDetail?.last_four_digit}
                          expires={`${cardDetail?.expiry_month} / ${cardDetail?.expiry_year}`}
                          selected={
                            primaryCard?.primary_card_id === cardDetail?.id
                              ? true
                              : false
                          }
                          onClick={() => {
                            handleRadioChange(cardDetail?.id);
                          }}
                        />
                      </div>

                    );
                  })
                ) : (
                  <div className="flex flex-col space-y-2 w-full">
                    <p className="flex flex-col gap-y-3 w-full">
                      <img
                        src={Fail}
                        alt=""
                        className="w-[50%] max-w-[150px] mx-auto"
                      />
                      <p className="font-medium text-red-100 text-center">
                        Sorry, we do not have any of your card saved with us
                      </p>
                      <p className="text-gray-100 text-[14px] text-center">
                        Make a card deposit with us to start your autosave.
                      </p>
                    </p>

                    <div className="flex flex-col space-y-2 pt-4 ">
                      <div className="cursor-pointer">
                        <div
                          onClick={() => {
                            setModalType(ModalType.ADDFUNDS_VAULT);
                          }}
                          className="font-bold text-blue-100 flex gap-x-2 items-center"
                        >
                          <AiOutlinePlus color="#1C9FDA" />
                          <p>Save with other payment options</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-between py-3 px-2 md:py-6 items-center ">
                <p className=" text-blue-200 md:text-xl text-lg w-full font-medium">
                  I want to save automatically
                </p>
                <AppSwitch
                  className={" w-fit "}
                  checked={autoSaveBtn}
                  onChange={() => setAutoSaveBtn((prevState) => !prevState)}
                />
              </div>
            </div>
            <Button
              onClick={() => mainCall()}
              isBlue
              text="Update autosave changes"
              loading={submitting}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Autosave;
