import { lazy } from "react";
import { PUBLIC_PATHS, PRIVATE_PATHS } from "./constants";
import { Navigate } from "react-router-dom";
import WithSuspense from "layout/WithSuspense";

const {
  LOGIN,
  SIGNUP,
  LANDING,
  LANDINGDATA,
  REFER_SIGNUP,
  RESET_PASS,
  CHANGE_PASS,
  PASS_OTP,
  PUBLIC_PACKAGES,
  PUBLIC_PACKAGES_PARTNERSHIP,
  PACKAGES_DETAIL,
  GALLERY,
} = PUBLIC_PATHS;

const {
  DASHBOARD,
  SUPPORT,
  PACKAGES,
  PLANTRIP,
  PACKAGES_DETAILS,
  TRANSACTION,
  PROFILE,
  REFER,
  COUPON,
  CHAT,
  WISHLIST,
  POLICY,
  LEGAL,
  NOT_FOUND,
  OFFLINE,
  CUSTOMTRIP,
  TRIPVAULT,
} = PRIVATE_PATHS;

const Login = WithSuspense(lazy(() => import("../screens/auth/Login")));
const Signup = WithSuspense(lazy(() => import("../screens/auth/Signup")));
const ResetPassword = WithSuspense(
  lazy(() => import("../screens/auth/ResetPassword"))
);
const PassOtp = WithSuspense(lazy(() => import("../screens/auth/PassOtp")));
const ReferSignup = WithSuspense(
  lazy(() => import("../screens/auth/ReferSignup"))
);
const ChangePassword = WithSuspense(
  lazy(() => import("../screens/auth/ChangePassword"))
);
const Dashboard = WithSuspense(
  lazy(() => import("../screens/dashboard/Dashboard"))
);
const Packages = WithSuspense(lazy(() => import("../screens/packages/index")));
const Policy = WithSuspense(lazy(() => import("../screens/legal/Policy")));
const Terms = WithSuspense(lazy(() => import("../screens/legal/Terms")));
const Wishlist = WithSuspense(lazy(() => import("../screens/wishlist/index")));
const PackagesDetails = WithSuspense(
  lazy(() => import("../components/packages/PackageDetails"))
);
const Landing = WithSuspense(lazy(() => import("../screens/landing/Landing")));
const Gallery = WithSuspense(lazy(() => import("../screens/gallery/index")));
const Chat = WithSuspense(lazy(() => import("../screens/chat")));
const ProfileForm = WithSuspense(
  lazy(() => import("../components/profile/index"))
);
const Support = WithSuspense(
  lazy(() => import("../components/profile/Support"))
);
const CustomTrips = WithSuspense(
  lazy(() => import("../components/customtrips/index"))
);
const Referal = WithSuspense(
  lazy(() => import("../components/referral/index"))
);
const Coupon = WithSuspense(lazy(() => import("../components/coupon/index")));
const Transaction = WithSuspense(
  lazy(() => import("../components/transactionHistory"))
);
const PlanTrip = WithSuspense(lazy(() => import("../screens//plantrip/index")));
const Notfound = WithSuspense(
  lazy(() => import("../screens/statePages/Notfound"))
);
const Offline = WithSuspense(
  lazy(() => import("../screens/statePages/Offline"))
);
const TripVault = WithSuspense(
  lazy(() => import("../components/dashboard/TripVault"))
);

export const PUBLIC_ROUTES = [
  { path: LOGIN, element: <Login /> },
  { path: SIGNUP, element: <Signup /> },
  { path: LANDING, element: <Landing /> },
  { path: LANDINGDATA, element: <Landing /> },
  { path: REFER_SIGNUP, element: <ReferSignup /> },
  { path: CHANGE_PASS, element: <ChangePassword /> },
  { path: RESET_PASS, element: <ResetPassword /> },
  { path: PASS_OTP, element: <PassOtp /> },
  { path: LEGAL, element: <Terms /> },
  { path: POLICY, element: <Policy /> },
  { path: GALLERY, element: <Gallery /> },
  { path: PUBLIC_PACKAGES, element: <Packages /> },
  { path: PUBLIC_PACKAGES_PARTNERSHIP, element: <Packages /> },
  { path: PACKAGES_DETAIL, element: <PackagesDetails /> },
  { path: "*", element: <Navigate to="/" replace /> },
];

export const PRIVATE_ROUTES = [
  { path: PACKAGES_DETAILS, element: <PackagesDetails /> },
  { path: DASHBOARD, element: <Dashboard /> },
  { path: PACKAGES, element: <Packages /> },
  { path: WISHLIST, element: <Wishlist /> },
  { path: CHAT, element: <Chat /> },
  { path: PROFILE, element: <ProfileForm /> },
  { path: TRANSACTION, element: <Transaction /> },
  { path: SUPPORT, element: <Support /> },
  { path: REFER, element: <Referal /> },
  { path: COUPON, element: <Coupon /> },
  { path: CUSTOMTRIP, element: <CustomTrips /> },
  { path: TRIPVAULT, element: <TripVault /> },
  { path: PLANTRIP, element: <PlanTrip /> },
  { path: LEGAL, element: <Terms /> },
  { path: POLICY, element: <Policy /> },
  { path: "/", element: <Navigate to="/dashboard" replace /> },
  { path: "*", element: <Navigate to="/404" replace /> },
  { path: NOT_FOUND, element: <Notfound /> },
  { path: OFFLINE, element: <Offline /> },
];
