import React from "react";
import { useState, useEffect, useRef } from "react";
import { BiLoaderCircle } from "react-icons/bi";
import AuthCode from "react-auth-code-input";
import { IoIosArrowDown } from "react-icons/io";
import CopyToClipboard from "react-copy-to-clipboard";

const Input = ({
  withLabel,
  ispassword,
  label,
  name,
  className,
  br,
  type,
  placeholder,
  value,
  onChange,
  disabled,
  required,
  max,
  min,
  containerClass,
  maxLength,
  step,
  icon,
  labelClassName,
  currency,
  onCopy,
  link,
  days,
  inButton,
  btn_text,
  onClickBtn,
  loading,
  checked,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const accType = sessionStorage.getItem("account_type");
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const passwordType = showPassword ? "text" : "password";

  return (
    <div className={`${containerClass}`}>
      {withLabel && (
        <label
          className={`${labelClassName} leading-10 text-base text-gray-100 font-medium pl-3 `}
        >
          {label}
        </label>
      )}
      {!br && !inButton && <br />}
      <div className="relative">
        <input
          max={max}
          min={min}
          maxLength={maxLength}
          name={name}
          className={`${className} w-full h-12 border border-gray-100 border-opacity-50 rounded px-7 bg-gray-50 bg-opacity-10 text-blue-200 text-sm  placeholder:text-sm md:text-base md:placeholder:text-base  font-medium placeholder-opacity-50 placeholder-gray-100 hover:border-blue-100 disabled:opacity-60 focus:border-blue-100  focus:outline-none `}
          type={ispassword ? passwordType : type}
          placeholder={placeholder}
          value={value}
          autoComplete="on"
          disabled={disabled}
          onChange={onChange}
          required={required}
          step={step}
          checked={checked}
        />
        {ispassword && (
          <button
            type="button"
            onClick={togglePassword}
            className="absolute inset-y-0 right-0 flex items-center px-4 font-semibold text-gray-100 text-xs rounded-r-lg hover:text-blue-100"
          >
            SHOW
          </button>
        )}
        {inButton && (
          <button
            type="button"
            onClick={onClickBtn}
            className="absolute inset-y-0 right-0 flex items-center px- font-medium text-white-100 text-base rounded-r-lg bg-blue-100 focus:bg-opacity-80"
          >
            {loading ? (
              <span className="">
                <BiLoaderCircle
                  size={32}
                  className="mx-auto animate-spin-slow"
                />
              </span>
            ) : !loading ? (
              <div className="ml_10">{btn_text}</div>
            ) : null}
          </button>
        )}
        {currency && (
          <p className="absolute inset-y-0 left-0 flex items-center pl-6 font-semibold text-black-100 text-base rounded-l-lg">
            {accType?.includes("NIGERIAN") ? "₦" : "$"}
          </p>
        )}
        {icon && (
          <CopyToClipboard onCopy={onCopy} text={link}>
            <div className="absolute cursor-pointer inset-y-0 right-[11px] flex items-center">
              <img src={icon} />
            </div>
          </CopyToClipboard>
        )}
        {days && (
          <p className="absolute inset-y-0 right-0 flex items-center pr-6 font-semibold text-black-100 text-base rounded-r-lg">
            Days
          </p>
        )}
      </div>
    </div>
  );
};

const SelectInput = ({
  withLabel,
  label,
  name,
  className,
  type,
  placeholder,
  value,
  onChange,
  opt,
  disabled,
  required,
  containerClass,
  labelClass,
  step,
  options,
  onClick,
  wrapClass,
  br,
  sort,
}) => {
  const listedItems = options?.map((option, index) => {
    return (
      <option
        value={opt ? option : sort ? option.days : option.name}
        className="text-gray-300"
        key={index}
      >
        {opt ? option : sort ? option.name : `${option.name} ${option.days}`}
      </option>
    );
  });

  return (
    <div className={` ${containerClass}`}>
      {withLabel && (
        <label
          className={`${labelClass} text-[14px] font-medium text-[#093549] mb-[15px]`}
        >
          {label}
        </label>
      )}
      {!br && <br />}

      <div
        className={`${wrapClass} rounded-[8px] relative border border-gray-100 border-opacity-50 hover:border-blue-100 focus:border-blue-100  focus:outline-none`}
      >
        <select
          name={name}
          className={`capitalize text-gray-100 w-full h-12 px-7 bg-gray-50 bg-opacity-10 text-sm  placeholder:text-sm md:text-base md:placeholder:text-base font-medium placeholder-opacity-50 placeholder-gray-100 focus:outline-none  ${className}`}
          placeholder={placeholder}
          value={type === "file" ? null : value}
          autoComplete="on"
          disabled={disabled}
          onChange={onChange}
          required={required}
          onClick={onClick}
          step={step}
        >
          <option disabled selected>
            {placeholder}
          </option>
          {listedItems}
        </select>
      </div>
    </div>
  );
};

const Datalist = ({
  withLabel,
  label,
  name,
  className,
  type,
  placeholder,
  value,
  onChange,
  disabled,
  required,
  containerClass,
  step,
  options,
  list,
  wrapClass,
  all,
}) => {
  const listedItems = options?.map((option, index) => (
    <option className="" value={all ? option : option?.id}>
      {option?.name}
    </option>
  ));

  return (
    <div className={`${containerClass}`}>
      {withLabel && (
        <label className="leading-10 text-base text-gray-100 font-medium pl-3 mb-4">
          {label}
        </label>
      )}
      {withLabel && <br />}
      <div
        className={`${wrapClass} relative border border-gray-100 border-opacity-50 rounded hover:border-blue-100 focus:border-blue-100  focus:outline-none`}
      >
        <select
          name={name}
          className={`capitalize text-gray-100 w-full h-12 px-7 bg-gray-50 bg-opacity-10 text-sm  placeholder:text-sm md:text-base md:placeholder:text-base  font-medium placeholder-opacity-50 placeholder-gray-100 focus:outline-none ${className}`}
          placeholder={placeholder}
          value={type === "file" ? null : value}
          autoComplete="on"
          disabled={disabled}
          onChange={onChange}
          required={required}
          step={step}
          type={type}
          list={list}
        >
          <option disabled selected>
            {placeholder}
          </option>
          {listedItems}
        </select>
      </div>
    </div>
  );
};

const RadioInput = ({
  withLabel,
  label,
  name,
  className,

  value,
  containerClass,
  id,
  options,
}) => {
  return (
    <div className={`${containerClass}`}>
      {withLabel && (
        <label className="leading-10 text-base text-gray-100 font-medium pl-3 mb-4">
          {label}
        </label>
      )}
      <br />
      <div className="relative">
        {options.map((option) => {
          return (
            <input
              className={`capitalize text-gray-100 w-full h-12 border border-gray-100 border-opacity-50 rounded px-7 bg-gray-50 bg-opacity-10 text-sm  placeholder:text-sm md:text-base md:placeholder:text-base  font-medium placeholder-opacity-50 placeholder-gray-100 hover:border-blue-100 focus:border-blue-100  focus:outline-none ${className}`}
              name={name}
              value={value}
              type="radio"
              id={id}
            />
          );
        })}
      </div>
    </div>
  );
};

const OtpInput = ({
  placeholder,
  onChange,
  length,
  disabled,
  inputClass,
  containerClass,
}) => {
  return (
    <>
      <AuthCode
        allowedCharacters="numeric"
        onChange={onChange}
        length={length}
        containerClassName={containerClass}
        inputClassName={inputClass}
        disabled={disabled}
        placeholder={placeholder}
      />
    </>
  );
};

const CustomInput = ({
  placeholder,
  check,
  show,
  value,
  onChange,
  code,
  isEdit,
  password,
  onClick,
  setCode,
  name,
  onFocus,
  type,
  onBlur,
  ref,
  label,
  data,
  error,
  isChecked,
}) => {
  const [num, setNum] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".box") === null) {
        setNum(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [filter, setFilter] = useState("");

  const lists = data?.filter(
    (list) =>
      !filter || list?.name.toLowerCase().startsWith(filter.toLowerCase())
  );
  const searchBoxRef = useRef(null);

  useEffect(() => {
    const node = searchBoxRef.current;
    if (num) {
      node && node.focus();
    } else {
      node && node.blur();
    }
  }, [num]);

  useEffect(() => {
    if (!num) {
      setFilter("");
    }
  }, [num]);

  return (
    <div>
      <div className="text-[14px] font-medium text-[#093549] mb-[5px]">
        {label}
      </div>
      {type === "number" ? (
        <div>
          <div className="flex items-center">
            <div
              onClick={() => setNum((prev) => !prev)}
              className="flex cursor-pointer box relative rounded border rounded-l border-[#e0e0e0] h-[50px] items-center bg-[#F9F9F9] p-[15px] gap-[12px]"
            >
              <div>{code}</div>
              <IoIosArrowDown />
              {num && (
                <div className="bg-[white] z-[66] px-[10px] pt-[10px] left-0 top-[50px] w-[350px] h-[35vh] shadow-btn overflow-y-scroll absolute">
                  <input
                    value={filter}
                    ref={searchBoxRef}
                    disabled={isEdit}
                    onChange={(e) => setFilter(e.target.value)}
                    placeholder="Search for country"
                    className="border w-full mb-[10px] p-[16px] border-[#e0e0e0]
                   rounded-[8px] h-[30px] placeholder:text-[#667085] placeholder:text-[14px]"
                  />
                  {lists?.map((dat, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        setNum(true);
                        setCode(dat?.dial_code);
                      }}
                      className="flex cursor-pointer text-[14px] items-start gap-[10px]"
                    >
                      <div>{dat?.name}:</div>
                      <div className="pb-[10px] ">{dat?.dial_code}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <input
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              name={name}
              disabled={isEdit}
              ref={ref}
              onFocus={onFocus}
              onBlur={onBlur}
              type={type ? type : "text"}
              className={`border w-full p-[16px] ${
                error ? "border-[#FF0000]" : "border-[#e0e0e0]"
              } rounded-[8px] border-l-0 rounded-l-none h-[50px] placeholder:text-[#667085]`}
            />
          </div>
          <span className="text-[#FF0000] text-[12px]">{error}</span>
        </div>
      ) : show ? (
        <div>
          <div
            className={`flex gap-[15px] items-center h-[50px] pl-0 p-[16px] border rounded-[8px] ${
              error ? "border-[#FF0000] text-[#FF0000]" : "border-[#e0e0e0]"
            }`}
          >
            <input
              placeholder={placeholder}
              value={value}
              onBlur={onBlur}
              onFocus={onFocus}
              name={name}
              disabled={isEdit}
              onChange={onChange}
              type={type ? type : "text"}
              className={`w-full pl-[16px] border ${
                error ? "border-[#FF0000] text-[#FF0000]" : "border-[#e0e0e0]"
              } border-r-0  h-[50px] placeholder:text-[#667085]`}
            />
            <div
              className={`${onClick ? "flex cursor-pointer" : "hidden"}`}
              onClick={onClick}
            >
              {!password ? (
                <span className="text-blue-100 font-medium">Hide</span>
              ) : (
                <span className="text-blue-100 font-medium">Show</span>
              )}
            </div>
          </div>
          <span className="text-[#FF0000] text-[12px]">{error}</span>
        </div>
      ) : check ? (
        <input
          type="checkbox"
          disabled={isEdit}
          name={name}
          checked={isChecked}
          onChange={onChange}
          className={`border cursor-pointer w-[20px] h-[20px] p-[16px] ${
            error ? "border-[#FF0000] text-[#FF0000]" : "border-[#e0e0e0]"
          } rounded-[4px]`}
        />
      ) : (
        <div>
          <input
            placeholder={placeholder}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={isEdit}
            name={name}
            value={value}
            onChange={onChange}
            type={type ? type : "text"}
            className={`border w-full p-[16px] ${
              error ? "border-[#FF0000] text-[#FF0000]" : "border-[#e0e0e0]"
            } rounded-[8px] h-[50px] placeholder:text-[#667085]`}
          />
          <span className="text-[#FF0000] text-[12px]">{error}</span>
        </div>
      )}
    </div>
  );
};

export { Input, SelectInput, CustomInput, RadioInput, Datalist, OtpInput };
