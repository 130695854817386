import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "services/firebase.config";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const accType = sessionStorage.getItem("account_type");

export const formatter = accType?.includes("NIGERIAN")
  ? new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0,
    })
  : new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });

export const debounce = (fn, delay) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const logoutUser = (navigate) => {
  sessionStorage.clear();
  auth.signOut();
  navigate("/");
  window.location.reload();
};

export const isInThePast = (date) => {
  const today = new Date();
  const newDate = new Date(date);

  // 👇️ OPTIONAL!
  // This line sets the hour of the current date to midnight
  // so the comparison only returns `true` if the passed in date
  // is at least yesterday
  today.setHours(0, 0, 0, 0);

  return newDate < today;
};

export const formatDate = (date, fallback = "") => {
  if (!date) return fallback;

  return new Date(date).toLocaleDateString("default", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
};

export const trim = (str) => {
  return str?.length > 45 ? str.substring(0, 45) + "..." : str;
};

export function closestNumber(r_days, freq) {
  let q = Math.floor(r_days / freq);

  let n1 = freq * q;

  let n2 = r_days * freq > 0 ? freq * (q + 1) : freq * (q - 1);

  if (Math.abs(r_days - n1) < Math.abs(r_days - n2)) return n1;

  return n2;
}

export function getNumberOfDays(targetDate) {
  const today = new Date().toJSON();

  const date1 = new Date(moment(today).format("l"));
  const date2 = new Date(moment(targetDate).format("l"));

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.floor(diffInTime / oneDay);

  return diffInDays;
}

export function getOriginalFilename(cloudinaryLink) {
  const urlParts = cloudinaryLink.split("/");
  const filenameWithExtension = urlParts[urlParts.length - 1];
  const filename = filenameWithExtension.split(".")[0];
  return filename;
}

export function isDatePastToday(date) {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0 for accurate comparison

  const inputDate = new Date(date);
  inputDate.setHours(0, 0, 0, 0);

  return inputDate < today;
}
