import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { LANDING } from "../../router/router";
import { ModalType } from "../../constants/modalType";
import { FaUser } from "react-icons/fa";
import { useQuery } from "@apollo/client";
import { LOAD_DISPLAY_PICTURE, LOAD_PROFILE_INFO } from "../../GraphQL/Queries";
import Toast, { warningToast } from "components/toast/toast";
import { headers } from "components/items";
import IconWithText from "components/header/IconWithText";
import Modal from "components/modal/Modal";
import { logoutUser } from "utils/helpers";
import profileStore from "stores/profileStore";
import { observer } from "mobx-react-lite";

const Header = () => {
  const { updateProfileInfo } = profileStore;

  const [modalType, setModalType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [displayPicture, setDisplayPicture] = useState([]);
  const [user, setUser] = useState();

  const { pathname } = useLocation();

  const handleProfileSetings = () => {
    setModalType(ModalType.PROFILE);
    setShowModal(!showModal);
  };

  const { data } = useQuery(LOAD_DISPLAY_PICTURE);
  useEffect(() => {
    if (data) {
      setDisplayPicture(data.me.user.images);
    }
  }, [data]);

  const response = useQuery(LOAD_PROFILE_INFO);

  useEffect(() => {
    if (response.data) {
      setUser(response.data.me.user);
    } else if (
      response.observable.last.error?.networkError?.statusCode == 401
    ) {
      alert("Please login user");
      logoutUser();
      warningToast(
        "Warning",
        response.observable.last.error?.networkError?.statusCode
      );
    }
  }, [response.data, response]);

  useEffect(() => {
    updateProfileInfo(user);
  }, [user]);

  return (
    <header className="font-sans z-[555] w-full fixed border-b border-[#e0e0e0] bg-white-100 h-[78px] flex flex-col justify-center">
      <Modal
        showModal={showModal}
        modalType={modalType}
        setModalType={setModalType}
        setShowModal={setShowModal}
        handleProfileSetings={handleProfileSetings}
      />
      <div className="z-[10001]">
        <Toast />
      </div>
      <div className="flex justify-between items-center cursor-pointer xs:px-[24px] md:px-[65px]">
        <div className="w-[100px]">
          <NavLink to={LANDING}>
            <Logo className="w-full h-full" />
          </NavLink>
        </div>
        <div
          className={`w-auto xs:hidden md:flex flex-row justify-between items-center gap-[45px] text-gray-100 font-medium text-[18px] z-[300]`}
        >
          {headers.map((data) => (
            <NavLink
              key={data.id}
              to={data.route}
              className={({ isActive }) =>
                isActive
                  ? "pb-[9px] text-blue-100 border-b-4 border-blue-100"
                  : "pb-[9px]"
              }
            >
              {pathname === data.route ? (
                <IconWithText
                  text={data.title}
                  Icon={data.active}
                  coloredText="header"
                />
              ) : (
                <IconWithText
                  text={data.title}
                  Icon={data.icon}
                  coloredText="header"
                />
              )}
            </NavLink>
          ))}
        </div>

        <div className="flex justify-between items-center gap-5">
          {displayPicture[0] === undefined ? (
            <div onClick={handleProfileSetings} className="">
              <FaUser
                color="#6D7079"
                stroke=""
                size={35}
                className="bg-white-100 drop-shadow-md p-2 rounded-[50%]"
              />
            </div>
          ) : (
            <div onClick={handleProfileSetings}>
              <img
                src={displayPicture[0]}
                alt=""
                className="w-8 h-8 object-cover rounded-[50%]"
              />
            </div>
          )}
        </div>
      </div>

      <div
        className={`fixed border-t border-[#e0e0e0] bottom-0 w-full h-[65px] bg-[white] xs:flex md:hidden justify-between flex-row items-center text-gray-100 text-lg z-[900]`}
      >
        {headers.map((data) => (
          <NavLink
            key={data.id}
            to={data.route}
            className={({ isActive }) => (isActive ? "text-blue-100" : "")}
          >
            {pathname === data.route ? (
              <IconWithText
                text={data.title}
                Icon={data.active}
                coloredText="header"
              />
            ) : (
              <IconWithText
                text={data.title}
                Icon={data.icon}
                coloredText="header"
              />
            )}
          </NavLink>
        ))}
      </div>
    </header>
  );
};

export default observer(Header);
