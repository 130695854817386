import React from "react";
import { observer } from "mobx-react-lite";
import profileStore from "stores/profileStore";

const SmallFooter = () => {
  const { currentAccType, setAccType } = profileStore;
  const AccType =
    sessionStorage.getItem("account_type") === "DOLLAR_ACCOUNT" ||
    currentAccType === "" ||
    currentAccType === "Nigeria";

  return (
    <div className="bg-white-100 py-5 px-5 flex md:flex-row flex-wrap justify-center item-center  ">
      <p className="text-gray-100 mx-auto">
        {AccType ? "Authentic African Experiences" : "Pocket Friendly Holidays"}
      </p>

      <p className="text-gray-100 mx-auto">
        Reach out to us:{" "}
        <span className="text-blue-100">
          {AccType ? "+1 347 407 5776" : "+234 809 237 8111"}
        </span>{" "}
      </p>

      <p className="text-gray-100 mx-auto">
        Email:{" "}
        <a href="mailto:info@travbelbayonline.com" className="text-blue-100">
          info@travbelbayonline.com
        </a>
      </p>
    </div>
  );
};

export default observer(SmallFooter);
