import React from "react";
import { Button } from "components/buttons";
import { images } from "constants";

export const TripSuccessModal = ({ open, vault }) => {
  return (
    <>
      {open && (
        <div className="absolute rounded-[8px] w-[100%] h-[100%] top-0 left-0 z-[1000] grid place-content-center gradient">
          <div className="absolute shadow-btn top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 box-border w-[600px] xs:max-w-[calc(100%_-_80px)] max-w-[calc(100%_-_50px)] px-[16px] py-[24px]  flex flex-col justify-center items-center text-[black] h-fit bg-[white] rounded-[8px]">
            <img src={images.checkMark} />
            <div className="text-[#093549] font-bold">
              Trip request successful
            </div>
            {vault ? (
              <div className="text-[#667085] mt-[8px] text-center text-[14px] mb-[30px]">
                You have successfully trip vault where you can <br /> save
                towards your dream trip
              </div>
            ) : (
              <div className="text-[#667085] mt-[8px] text-center text-[14px] mb-[30px]">
                You have successfully sent a request for your <br /> custom
                trip, an estimate would be sent to your <br /> email address
                shortly.
              </div>
            )}

            {vault ? (
              <div className="flex w-full gap-[30px] items-center">
                <Button
                  className="border-2 rounded-lg border-blue-100 h-[56px] font-medium w-[40%] lg:text-[14px] text-[13px] sm:w-[50%] md:w-[50%]"
                  text="View vault"
                />

                <Button
                  text="Explore packages"
                  isBlue
                  className="font-medium text-[13px] lg:text-[14px] h-[56px] w-[40%] sm:w-[50%] md:w-[50%]"
                />
              </div>
            ) : (
              <button className="bg-blue-100 rounded-[8px] w-full text-[white] h-[48px]">
                Go back to dashboard
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TripSuccessModal;
