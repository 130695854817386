import React, { useEffect } from "react";
import { Close } from "@material-ui/icons";
import { CustomInput } from "components/inputs";
import { CustomSelect } from "components/input/Input";

export const TripVaultModal = ({
  open,
  amt,
  setAmt,
  create,
  personal,
  setShow,
  show,
}) => {
  const savingFrequencies = ["Daily", "Weekly", "Monthly"];

  useEffect(() => {
    if (personal) {
      setTimeout(() => {
        setShow(true);
      }, 1000);
      setTimeout(() => {
        setShow(false);
        setAmt(true);
      }, 3000);
    }
  }, [personal]);

  return (
    <>
      {open && (
        <div className="absolute rounded-[8px] w-[100%] h-[100%] top-0 left-0 grid place-content-center gradient">
          <div className="absolute shadow-btn top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 box-border w-[600px] xs:max-w-[calc(100%_-_80px)] max-w-[calc(100%_-_50px)] px-[16px] py-[24px] text-[black] h-fit bg-[white]  rounded-[8px]">
            <Close />

            {create ? (
              <>
                <div className="font-bold text-[#093549] my-[20px]">
                  Create a trip vault{" "}
                </div>

                <div className="bg-blue-100 font-medium text-[white] rounded-[8px] py-[28px] px-[24px]">
                  Personal Vault
                </div>
                <div className="bg-[#FEBB02] mt-[16px] font-medium text-[#093549] rounded-[8px] py-[28px] px-[24px]">
                  Joint Trip Vault
                </div>
              </>
            ) : (
              personal && (
                <div className="text-[#093549]">
                  <div className="font-bold my-[20px]">
                    Create personal package vault
                  </div>

                  <div>
                    <div className="text-[12px] font-medium">
                      Trip Description
                    </div>
                    <CustomInput placeholder="E.g Trip to London" />

                    <div className="text-[12px] my-[8px] font-medium">
                      Select a saving Frequency
                    </div>
                    <CustomSelect
                      label={amt ? "Monthly (30 days)" : "Custom"}
                      setShow={setShow}
                      show={show}
                      options={savingFrequencies}
                    />
                  </div>
                  {amt && (
                    <div className="text-[13px] ">
                      <div className="flex text-[#093549] items-center mt-[12px] gap-[8px]">
                        <div>Target amount: </div>
                        <div className="font-medium">N2,020,00 </div>
                      </div>
                      <div className="flex text-[#093549] items-center mt-[12px] gap-[8px]">
                        <div>Frequency: </div>
                        <div className="font-medium">N102,000 (monthly) </div>
                      </div>
                    </div>
                  )}

                  <button className="bg-blue-100 xs:mt-[30px] lg:mt-[40px] h-[40px] rounded-[8px] text-white-100 w-full">
                    Create a vault
                  </button>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TripVaultModal;
