export const LANDING = "/";
export const LOGIN = "/auth/login";
export const AUTH = "/auth";
export const SIGNUP = "/auth/signup";
export const REFER_SIGNUP = "/auth/signup/referralCode/:referral_code";
export const RESET_PASS = "/auth/reset-password"
export const PASS_OTP = "/auth/confirm-password-otp"
export const CHANGE_PASS = "/auth/change-password"
export const FORGOTPASSWORD = "/forgotpassword";
export const DASHBOARD = "/dashboard";
export const PACKAGES = "/packages";
export const PACKAGES_DETAILS = "/packages/:id";
export const TRIPS = "/trips";
export const WISHLIST = "/wishlist";
export const CHAT = "/chat";
export const ESTIMATE = "estimate";
export const LEGAL = "legal";
export const TERMS = "terms";
export const POLICY = "policy";
export const PROFILE = "profile";
export const PLAN_TRIP = "/plan-trip";