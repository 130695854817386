import { gql } from "@apollo/client";

export const LOAD_USERS = gql`
  query {
    getUsers {
      firstName
      lastName
      email
      id
    }
  }
`;

export const LOAD_ADMINS = gql`
  query {
    getAdmins(page: 1, limit: 20) {
      __typename
      ... on AdminSuccess {
        admins {
          firstName
          lastName
          id
          phoneNumber
          email
          role
          role_type
          images
        }
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const LOAD_CHAT_ADMINS = gql`
  query {
    getChatAdmins {
      __typename
      ... on ChatAdminSuccess {
        users {
          id
          user {
            firstName
            lastName
            id
            phoneNumber
            email
            role
            role_type
            images
          }
        }
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const LOAD_CARD_DETAILS = gql`
  query {
    me {
      __typename
      ... on UserQuerySuccess {
        user {
          cards {
            id
            card_name
            token
            status
            last_four_digit
            country_code
            expiry_month
            expiry_year
          }
        }
      }
    }
  }
`;

export const LOAD_PROFILE_INFO = gql`
  query {
    me {
      __typename
      ... on UserQuerySuccess {
        user {
          firstName
          lastName
          email
          id
          phoneNumber
          account_type
          images
          isVerified
          cards {
            id
          }
        }
      }
    }
  }
`;

export const REFERAL = gql`
  query {
    me {
      __typename
      ... on UserQuerySuccess {
        totalReferred
        user {
          referred
          referral_code
          referral_wallet
          referrals {
            id
            referred
            status
            reward
            createdAt
          }
        }
      }
    }
  }
`;
export const COUPONS = gql`
  query {
    me {
      __typename
      ... on UserQuerySuccess {
        user {
          coupons {
            couponId {
              id
              promotionName
              discountPercentage
              couponCode
              maxUsers
              expiresAt
            }
            userId
            usedCoupon
          }
        }
      }
    }
  }
`;

export const LOAD_DISPLAY_PICTURE = gql`
  query {
    me {
      __typename
      ... on UserQuerySuccess {
        user {
          images
        }
      }
    }
  }
`;

export const LOAD_PACKAGES = gql`
  query (
    $page: Int
    $limit: Int
    $number_travellers: Int
    $destination: String
    $departure_date: Date
    $return_date: Date
    $min_budget: Int
    $max_budget: Int
    $account_type: AccountType
    $categories: [ID]
  ) {
    getPackages(
      page: $page
      limit: $limit
      number_travellers: $number_travellers
      destination: $destination
      departure_date: $departure_date
      return_date: $return_date
      min_budget: $min_budget
      max_budget: $max_budget
      account_type: $account_type
      categories: $categories
    ) {
      __typename
      ... on PackageSuccess {
        total
        destinations
        packages {
          id
          name
          summary
          description
          destination_address
          destination_lat: destination_long
          origin
          departure_date
          return_date
          booking_deadline_date
          hotel_rating
          number_adults
          number_children
          number_infants
          room_rates {
            id
            room_type
            rate
            variant_week
          }
          variable_weeks
          visa_authorization
          minimum_percentage
          minimum_travellers
          non_refundable_amount
          country
          images
          isFavourite
          favourites
          account_type
          categories {
            id
            categoryId {
              id
              name
            }
          }
        }
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const PROMOTION_PACKAGES = gql`
  query ($account_type: AccountType!) {
    getPromotionPackages(account_type: $account_type) {
      __typename
      ... on PromotionPackageQuerySuccess {
        packages {
          id
          package {
            id
            name
            summary
            description
            destination_address
            origin
            departure_date
            return_date
            booking_deadline_date
            hotel_rating
            number_adults
            number_children
            number_infants
            room_rates {
              id
              room_type
              rate
              variant_week
            }
            variable_weeks
            visa_authorization
            minimum_percentage
            minimum_travellers
            non_refundable_amount
            country
            images
            favourites
          }
        }
      }
    }
  }
`;

export const LOAD_PACKAGE = gql`
  query ($packageId: ID!) {
    getPackage(id: $packageId) {
      id
      name
      summary
      description
      destination_address
      destination_lat: destination_long
      origin
      departure_date
      return_date
      booking_deadline_date
      hotel_rating
      number_adults
      number_children
      number_infants
      room_rates {
        id
        room_type
        rate
        variant_week
      }
      variable_weeks
      visa_authorization
      minimum_percentage
      minimum_travellers
      non_refundable_amount
      country
      images
      favourites
      account_type
      categories {
        categoryId {
          id
          name
        }
      }
    }
  }
`;

export const LOAD_SINGLE_PACKAGE_IMAGE = gql`
  query ($packageId: ID!) {
    getPackage(id: $packageId) {
      images
    }
  }
`;
export const LOAD_TRIPS = gql`
  query {
    me {
      __typename
      ... on UserQuerySuccess {
        user {
          favourites {
            package {
              id
              name
              summary
              description
              destination_address
              destination_lat: destination_long
              departure_date
              return_date
              booking_deadline_date
              hotel_rating
              number_adults
              number_children
              country
              images
              available
            }
          }
        }
      }
    }
  }
`;

export const LOAD_SAVINGSACCOUNT = gql`
  query {
    me {
      __typename
      ... on UserQuerySuccess {
        user {
          savingsAccounts {
            id
            name
            balance
            savings_frequency
            debit_interval
            autosave
            savings_type
            group_type
            savings_goal
            amount_to_charge
            next_debit_date
            package {
              name
              images
            }
            primary_card {
              id
              card_name
              token
              status
              last_four_digit
              country_code
            }
            target_date
            number_adults
            number_children
            number_infants
            completed
            trip_completed
            discount_fee
            createdAt
            payment_type
          }
        }
      }
    }
  }
`;
export const CUSTOM_TRIP = gql`
  query ($page: Int, $limit: Int, $status: TripStatus) {
    me(page: $page, limit: $limit, status: $status) {
      __typename
      ... on UserQuerySuccess {
        user {
          firstName
          lastName
          phoneNumber
          email
          custom_trips {
            id
            from_where
            to_where
            departure_date
            return_date
            booking_deadline_date
            booking_expiration_date
            hotel_rating
            number_adults
            number_children
            number_infants
            flight_class
            estimated_amount
            status
            receipt
            additional_info
            visa_support
            images
            favourites
            createdAt
            is_read
          }
        }
      }
    }
  }
`;

export const LOAD_TRANSACTION_HISTORY = gql`
  query {
    me {
      __typename
      ... on UserQuerySuccess {
        user {
          transactions {
            transaction_reference
            amount
            transaction_type
            createdAt
            initiated_by
            savings_account_id {
              id
              name
              balance
            }
          }
        }
      }
    }
  }
`;

export const LOAD_PACKAGES_WISHLIST = gql`
  query {
    me {
      __typename
      ... on UserQuerySuccess {
        user {
          favourites {
            package {
              id
              name
              summary
              description
              destination_address
              destination_lat: destination_long
              created_by: departure_date
              departure_date
              return_date
              booking_deadline_date
              hotel_rating
              number_adults
              number_children
              number_infants
              room_rates {
                id
                room_type
                rate
                variant_week
              }
              country
              images
            }
          }
        }
      }
    }
  }
`;

export const LOAD_TRIP_WISHLIST = gql`
  query {
    me {
      __typename
      ... on UserQuerySuccess {
        user {
          favourites {
            trip {
              id
              name
              summary
              description
              destination_address
              destination_lat: destination_long
              created_by: departure_date
              return_date
              booking_deadline_date
              hotel_rating
              number_adults
              number_children
              room_rates {
                id
                room_type
                rate
                variant_week
              }
              country
              images
            }
          }
        }
      }
    }
  }
`;

export const LOAD_WALLET = gql`
  query {
    me {
      __typename
      ... on UserQuerySuccess {
        user {
          currentAccount {
            id
            balance
            savings_frequency
            debit_interval
            autosave
          }
        }
      }
    }
  }
`;

export const SEARCH_FOR_USER = gql`
  query ($userEmail: Email!) {
    searchThroughUsers(searchQuery: $userEmail) {
      id
      firstName
      email
    }
  }
`;

export const LOAD_GALLERY = gql`
  query {
    getGalleries {
      __typename
      ... on GalleryQuerySuccess {
        galleries {
          id
          name
          description
          images
          target_words
          country
        }
      }

      ... on BadRequest {
        message
      }
    }
  }
`;
export const GET_PACKAGE_CATEGORIES = gql`
  query {
    getCategories {
      __typename
      ... on CategorySuccess {
        categories {
          id
          name
        }
      }

      ... on BadRequest {
        message
      }
    }
  }
`;
