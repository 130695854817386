import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASECONFIG_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASECONFIG_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASECONFIG_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASECONFIG_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASECONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASECONFIG_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASECONFIG_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASECONFIG_MEASUREMENT_ID,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = () => auth.signInWithPopup(provider);

export const storage = getStorage();
export const db = getFirestore();

export default firebase;
