import React from "react";
import { Close } from "@material-ui/icons";
import { formatDate } from "utils/helpers";

export const Details = ({ label, data }) => {
  return (
    <div className="flex mb-[8px] items-center justify-between w-[80%]">
      <div className="text-[#667085]">{label}</div>
      <div className="font-medium text-[#344054]">{data}</div>
    </div>
  );
};

const CustomTripRequest = ({ onClose, customTrips, user }) => {
  return (
    <div>
      <div className="bg-white-100 pb-[60px] py-[16px] px-[24px] rounded-[8px]">
        <div className="flex justify-end">
          <div
            onClick={onClose}
            className="flex cursor-pointer justify-center items-center p-1 text-white-100 bg-blue-100 rounded-full w-fit"
          >
            <Close />
          </div>
        </div>

        <div className="mt-[10px] text-[#121212] text-[22px] font-bold">
          Custom Trip Request
        </div>
        <div className="mt-[8px] text-[#667085] text-[14px]">
          Below is an overview of your custom trip request
        </div>

        <div className="flex xs:flex-col md:flex-row gap-[40px] w-[full] mt-[36px] items-start">
          <div className="w-full">
            <div>
              <div className="font-medium text-[#101C28] text-[18px] mb-[8px]">
                Personal Information
              </div>
              <Details
                label="Passenger Name"
                data={`${user?.firstName} ${user?.lastName}`}
              />
              <div className="mt-[18px] font-medium text-[#101C28] text-[18px] mb-[8px]">
                Contact Information
              </div>
              <Details label="Email Address" data={user?.email} />
              <Details label="Phone Number" data={user?.phoneNumber} />
              {customTrips?.additional_info && (
                <>
                  <div className="mt-[18px] font-medium text-[#101C28] text-[18px] mb-[8px]">
                    Additional Information
                  </div>
                  <div className="text-[#667085]">
                    {customTrips?.additional_info}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="w-full">
            <div className="font-medium text-[#101C28] text-[18px] mb-[8px]">
              Flight details
            </div>

            <Details label="From where" data={customTrips?.from_where} />
            <Details label="To where" data={customTrips?.to_where} />
            <Details
              label="Leaving on"
              data={formatDate(customTrips?.departure_date)}
            />
            <Details
              label="Returning on"
              data={formatDate(customTrips?.return_date)}
            />
            <Details
              label="Preferred hotel rating"
              data={customTrips?.hotel_rating}
            />
            <Details
              label="Number of Travellers"
              data={
                customTrips?.number_adults +
                customTrips?.number_children +
                customTrips?.number_infants
              }
            />
            <Details
              label="Preferred flight class"
              data={customTrips?.flight_class}
            />
            <Details
              label="Visa Support"
              data={customTrips?.visa_support === false ? "No" : "Yes"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomTripRequest;
