import React, { useEffect, useState } from "react";

const Timer = ({ minute, className }) => {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(minute);

  function updateTime() {
    if (minutes === 0 && seconds === 0) {
      setSeconds(0);
      setMinutes(0);
    } else {
      if (seconds === 0) {
        setMinutes((minutes) => minutes - 1);
        setSeconds(59);
      } else {
        setSeconds((seconds) => seconds - 1);
      }
    }
  }

  useEffect(() => {
    // use set timeout and be confident because updateTime will cause rerender
    // rerender mean re call this effect => then it will be similar to how setinterval works
    // but with easy to understand logic
    if (minutes > 0 || minutes === 0) {
      let token;
      token = setTimeout(updateTime, 1000);

      return function cleanUp() {
        clearTimeout(token);
      };
    }
  });

  return (
    <p className={`${className}`}>
      {minutes === 0 && seconds === 0 ? (
        <div className="text-red-100">Expired</div>
      ) : (
        <div>
          Expires in: {minutes}:{seconds}
        </div>
      )}
    </p>
  );
};

export default Timer;
