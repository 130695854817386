import React, { useState, Fragment, useRef } from "react";
import Header from "./AuthLayout/Header";
import { images } from "constants";
import Bot from "components/modal/Bot";
import Footer from "./AuthLayout/Footer";
import UnAuthFooter from "layout/NonAuthLayout/Footer";
import LandingPageHeader from "./NonAuthLayout/LandingPageHeader";
import SideBar from "components/layout/SideBar";
import { useLocation, useParams } from "react-router-dom";
import SmallFooter from "./AuthLayout/SmallFooter";
import Toast from "components/toast/toast";

export const AuthLayout = ({ children }) => {
  const location = useLocation();
  const [show, setShow] = useState(false);

  return (
    <Fragment>
      <div className="overflow-x-hidden w-full bg-primary-100">
        <div className="overflow-x-hidden w-full mx-auto relative">
          <Header />
          <div
            className={`min-h-screen md:pt-[125px]  xs:pt-[88px] mdLg:pb-0 bg-[#fff] max-w-[1440px] mx-auto ${
              location.pathname === "/chat" || location.pathname === "/"
                ? ""
                : "xs:px-[24px] md:px-[65px]"
            }`}
          >
            {children}
          </div>
          {location.pathname !== "/404" && (
            <div>
              {location.pathname === "/tripvault" ||
              location.pathname === "/profile" ||
              location.pathname === "/customtrips" ||
              location.pathname === "/support" ||
              location.pathname === "/transactions" ||
              location.pathname === "/coupons" ||
              location.pathname === "/referal" ? (
                <div className="lg:pl-[21%]">
                  <SmallFooter />
                </div>
              ) : location.pathname === "/chat" ? (
                <SmallFooter />
              ) : (
                <Footer />
              )}
            </div>
          )}

          <div
            className="fixed z-[15] xs:bottom-20 md:bottom-0 right-5 cursor-pointer"
            onClick={() => setShow((prev) => !prev)}
          >
            <img src={images.bot} />
          </div>
          <Bot isOpen={show} onClose={() => setShow(false)} />
        </div>
      </div>
    </Fragment>
  );
};

export const NonAuthLayout = ({ children }) => {
  const secondSectionRef = useRef(null);
  const location = useLocation();

  const handleClick = () => {
    secondSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="overflow-x-hidden w-full bg-primary-100">
      <div className="overflow-x-hidden w-full mx-auto bg-white-100 relative">
        <div className="min-h-screen overflow-x-hidden">
          <div className="z-[10001]">
            <Toast />
          </div>
          {location.pathname === "/" ? (
            <div className=" bg-primary-100">
              <LandingPageHeader />
              <div className="pt-[90px] max-w-[1440px] mx-auto">{children}</div>
            </div>
          ) : location.pathname === "/gallery" ? (
            <div className=" bg-primary-100">
              <LandingPageHeader shadowed />

              <div className="pt-[90px] max-w-[1440px] mx-auto">{children}</div>
              <div className="mt-[5rem]">
                <UnAuthFooter AccType={true} />
              </div>
            </div>
          ) : location.pathname.includes("packages/location") ? (
            <div className="bg-[#fff]">
              <LandingPageHeader />
              <div className="pt-[90px] max-w-[1440px] mx-auto ">
                {children}
              </div>
            </div>
          ) : location.pathname.includes("auth") ? (
            <div className="flex lg:flex-row xs:flex-col min-h-screen">
              <div className="hidden">
                <LandingPageHeader />
              </div>

              <SideBar handleClick={handleClick} />
              <div
                ref={secondSectionRef}
                className="xs:px-[37px] lg:px-[80px] lg:w-[50%] sm:w-full pb-[100px] pt-[35px]"
              >
                <div className="z-[10001]">
                  <Toast />
                </div>
                {children}
              </div>
            </div>
          ) : (
            <div className="bg-primary-100">
              <LandingPageHeader />
              <div className="pt-[90px] max-w-[1440px] mx-auto">{children}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
