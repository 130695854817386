/**
 * Advanced example demonstrating all core MobX constructs.
 */
import { makeAutoObservable } from "mobx";

class ProfileStore {
  // ====================================================
  // State
  // ====================================================
  user = null;
  count = 0;
  error = null;

  loadingMe = false;
  me = [];
  currentAccType = "";

  constructor() {
    makeAutoObservable(this);
  }

  // ====================================================
  // Computed views

  // ====================================================
  // While MobX promotes OOP, we can still benefit from using FP where it's appropriate

  // ====================================================
  // Actions
  // ====================================================

  toggleFilter = () => {
    this.filter = !this.filter;
  };
  updateProfileInfo = (profileInfo) => {
    this.loadingMe = true;
    this.me = profileInfo;
    this.loadingMe = false;
  };
  setAccType = (acctype) => {
    this.currentAccType = acctype;
  };
}

export default new ProfileStore();
