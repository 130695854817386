import React from "react";
import { BiLoaderCircle } from "react-icons/bi";

const Loader = () => {
  return (
    <div
      id="modal-backdrop"
      className="fixed w-[100%] h-[100%] top-0 left-0 z-[1000] grid place-content-center bg-black-100/50"
    >
      <div className="modal_scroll fixed top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 box-border w-[900px] sm:max-w-[calc(100%_-_120px)] max-w-[calc(100%_-_40px)] z-40 my-2">
        <div className="absolute rounded-[8px] w-[100%] h-[100%] top-0 left-0 z-[1000] grid place-content-center">
          <BiLoaderCircle
            color="#1c9fda"
            size={50}
            className="mx-auto animate-spin-slow"
          />
        </div>
      </div>
    </div>
  );
};

export default Loader;
