import React, { useState } from "react";
import { BiLoaderCircle } from "react-icons/bi";
import { ReactComponent as Close } from "../../assets/img/close-modal.svg";
import { Input } from "../input/Input";
import { TRANSFER_CREDIT } from "GraphQL/Mutations";
import { LOAD_WALLET } from "GraphQL/Queries";
import { errorToast, successToast, warningToast } from "components/toast/toast";
import { useMutation, useQuery } from "@apollo/client";
import { REFERAL } from "GraphQL/Queries";

const AddEarning = ({ onClose }) => {
  const [amount, setAmount] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const walletBalance = useQuery(LOAD_WALLET);
  const [transfer] = useMutation(TRANSFER_CREDIT, {
    refetchQueries: [{ query: REFERAL }, { query: LOAD_WALLET }],
  });

  const debitWallet = () => {
    setSubmitting(true);
    transfer({
      variables: {
        amount: Number(amount),
        current_account_id: walletBalance?.data?.me?.user?.currentAccount?.id,
      },
    })
      .then((response) => {
        if (
          response.data.transferTravelCreditToCurrentAccount.__typename ===
          "BadRequest"
        ) {
          warningToast(
            "Warning",
            response.data.transferTravelCreditToCurrentAccount.message
          );
        }
        if (
          response.data.transferTravelCreditToCurrentAccount.__typename ===
          "Success"
        ) {
          successToast("Success", "Transfer successful");
          onClose();
        }
      })
      .catch((error) => {
        errorToast("Error", error ? error?.message : "An error occured");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="absolute rounded-[8px] w-[100%] h-[100%] top-0 left-0 z-[1000] grid place-content-center">
      <div onClick={onClose} />
      <div className="bg-[#fff] relative p-[20px] rounded-[8px]">
        <div
          onClick={onClose}
          className="cursor-pointer flex justify-end items-end "
        >
          <Close />
        </div>
        <div className="mt-[20px]">
          <div className="text-[#06202C] text-[18px] font-bold mb-[8px]">
            Add earnings to wallet
          </div>
          <div className="text-[#093549]">
            How much would you like to withdraw into your wallet?
          </div>

          <div className="mt-[30px]">
            <Input
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="TC 0.00"
              type="number"
              className="w-full text-center h-[54px] placeholder:text-center border-[#e0e0e0]"
            />
            <div className="mt-[8px] text-center text-[#667085] text-[14px] font-medium">
              Minimum amount: TC 2,000
            </div>
            <div className="my-2 mx-auto md:ml-auto md:mx-0 w-full ">
              <button
                onClick={debitWallet}
                type="submit"
                disabled={!amount}
                className="bg-blue-100 text-[white] mt-[40px] font-bold items-center justify-center flex rounded-[8px] h-[50px] w-full"
              >
                {submitting ? (
                  <div className="flex items-center gap-[20px]">
                    <BiLoaderCircle
                      size={32}
                      className="mx-auto animate-spin-slow"
                    />
                    <div>Withdrawing</div>
                  </div>
                ) : (
                  <div>Withdraw to wallet</div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEarning;
