import { Button } from "components/buttons";
import { Datalist } from "components/inputs";
import React, { useEffect, useState } from "react";
import { Close } from "@material-ui/icons";
import { LOAD_PACKAGES } from "GraphQL/Queries";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { useNavigate } from "react-router";

const FindAPackage = ({ onClose, triggerVault, singlePackageInfo }) => {
  const navigate = useNavigate();
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [allPackages, setAllPackages] = useState(null);

  const { error, loading, data } = useQuery(LOAD_PACKAGES, {
    variables: {
      page: 1,
      limit: 50,
    },
  });
  useEffect(() => {
    if (data) {
      setAllPackages(data.getPackages.packages);
    }
  }, [data]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const packageObj = allPackages?.find((pkg) => pkg?.id === value);
    setSelectedPackage(packageObj);
  };

  return (
    <div>
      <div>
        <span
          onClick={onClose}
          className="cursor-pointer absolute top-3 right-3 "
        >
          <Close />
        </span>
        <div className="flex pb-8 space-y-6 flex-col">
          <p className="text-blue-200 font-bold text-[20px] md:text-2xl ">
            Create a vault
          </p>

          <div className="flex flex-col gap-y-5 overflow-auto max-h-[calc(100vh_-_160px)] modal_scroll">
            <div className="flex space-y-4 flex-col min-h-[150px]">
              <div className="flex flex-col ">
                <p className="text-[#093549]/90 font-medium">
                  Select a package
                </p>
                <p className=" text-[#093549]/90 font-bold">
                  <Datalist
                    placeholder="Select A Package"
                    name={"package"}
                    options={allPackages}
                    type={"text"}
                    list={"data"}
                    onChange={handleChange}
                    value={selectedPackage}
                    required
                    wrapClass={selectedPackage === null ? "border-red-100" : ""}
                  />
                </p>
              </div>

              {selectedPackage && (
                <div className="pl-4 text-gray-100 font-medium">
                  <p>
                    Deadline :{" "}
                    <span className="text-[#344054]/90 font-bold">
                      {moment(selectedPackage?.booking_deadline_date).format(
                        "ll"
                      )}
                    </span>
                  </p>
                  <p>
                    Depature :{" "}
                    <span className="text-[#344054]/90 font-bold">
                      {moment(selectedPackage?.departure_date).format("ll")}
                    </span>
                  </p>
                  <p>
                    Return :{" "}
                    <span className="text-[#344054]/90 font-bold">
                      {moment(selectedPackage?.return_date).format("ll")}
                    </span>
                  </p>
                  <p>
                    Summary :{" "}
                    <span className="text-[#344054]/90 font-bold">
                      {selectedPackage?.summary}
                    </span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div>
            <Button
              onClick={() => {
                navigate(`/packages/${selectedPackage.id}`);
              }}
              isBlue
              text={"View details"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindAPackage;
