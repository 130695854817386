import React, { useEffect, useState } from "react";
import { ReactComponent as Close } from "../../assets/img/close-modal.svg";
import { Button, RoundButton } from "../buttons";
import { ReactComponent as Arrow } from "../../assets/img/right-arrow.svg";
import { ReactComponent as BackArrow } from "../../assets/img/modal-back-arrow.svg";
import Success from "../../assets/img/success.png";
import { Datalist, Input, SelectInput } from "../inputs";
import {
  LOAD_PACKAGE,
  LOAD_PACKAGES,
  LOAD_SAVINGSACCOUNT,
  SEARCH_FOR_USER,
} from "../../GraphQL/Queries";
import { useMutation, useQuery } from "@apollo/client";
import { TRIPVAULT_MUTATION } from "../../GraphQL/Mutations";
import moment from "moment";
import { errorToast, successToast, warningToast } from "components/toast/toast";

const CreateVault = ({ onClose }) => {
  const resolvers = {
    AuthType: {
      PACKAGE: "PACKAGE",
      TRIP: "TRIP",
      PERSONAL: "PERSONAL",
      JOINT: "JOINT",
      DAILY: "DAILY",
      WEEKLY: "WEEKLY",
      MONTHLY: "MONTHLY",
      CUSTOM: "CUSTOM",
    },
  };

  const [vaultType, setVaultType] = useState(false);
  const [vaultStep, setVaultStep] = useState(0);

  const [type, setType] = useState("");

  const [createVaultData, setCreateVaultData] = React.useState({
    package: null,
    autosave: false,
    savings_type: resolvers.AuthType.PACKAGE,
    group_type: "",
    target_date: "",
    debit_interval: null,
    description: "",
    targetAmount: "",
    frequencyAmount: "",
    savingFrequency: "",
    room_rate_id: "",
    id: "",
    joint_user_ids: [],
    number_adults: "",
    number_children: "",
    number_infants: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCreateVaultData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const handlePersonalVault = () => {
    setVaultStep(vaultStep + 1);
    setVaultType("PersonalVault");

    setCreateVaultData((prevState) => {
      return { ...prevState, group_type: resolvers.AuthType.PERSONAL };
    });
    filterPackages();
  };

  const handleJointVault = () => {
    setVaultStep(vaultStep + 1);
    setVaultType("JointVault");
    setCreateVaultData((prevState) => {
      return { ...prevState, group_type: resolvers.AuthType.JOINT };
    });
    filterPackages();
  };

  const [showJointUser, setShowJointUser] = useState(false);
  function searchJointUsers() {
    setShowJointUser((prevState) => !prevState);
  }

  const [jointUsers, setJointUsers] = useState("");
  function isChange(event) {
    const { value } = event.target;
    setJointUsers(value);
  }

  const [trigger, setTrigger] = useState(false);
  const [loadBtn, setLoadBtn] = useState(false);
  function addJointUsers() {
    setTrigger(!trigger);
    setLoadBtn(true);
  }

  const [allPackages, setAllPackages] = useState([]);
  const [packageList, setPackageList] = useState([]);

  const filterPackages = () => {
    for (let i = 0; i < allPackages?.length; i++) {
      if (getNumberOfDays(allPackages[i].booking_deadline_date) > 0) {
        packageList.push(allPackages[i]);
      }
    }
  };

  const loadPackages = useQuery(LOAD_PACKAGES);
  const userSearch = useQuery(SEARCH_FOR_USER, {
    variables: {
      userEmail: jointUsers,
    },
  });

  const debit_interval =
    createVaultData.savingFrequency == "DAILY"
      ? "1"
      : createVaultData.savingFrequency === "WEEKLY"
      ? "7"
      : createVaultData.savingFrequency === "MONTHLY"
      ? "30"
      : createVaultData.savingFrequency === "CUSTOM"
      ? createVaultData.debit_interval
      : "0";

  const loadPackage = useQuery(LOAD_PACKAGE, {
    variables: {
      packageId: createVaultData.package,
    },
  });
  const accType = sessionStorage.getItem("account_type");
  const formatter = accType?.includes("NIGERIAN")
    ? new Intl.NumberFormat("en-NG", {
        style: "currency",
        currency: "NGN",
        minimumFractionDigits: 0,
      })
    : new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });

  const [packagePrice, setPackagePrice] = useState("");
  const [packageDates, setPackageDates] = useState({
    deadline: "",
    depature: "",
    return: "",
  });
  const [packageFrequencyRate, setPackageFrequencyRate] = useState("");
  useEffect(() => {
    if (loadPackage.data) {
      setPackagePrice(formatter.format(loadPackage?.data?.getPackage?.rate));
      setCreateVaultData((prevState) => {
        return {
          ...prevState,
          number_adults: loadPackage?.data?.getPackage?.number_adults,
          number_children: loadPackage?.data?.getPackage?.number_children,
          number_infants: loadPackage?.data?.getPackage?.number_infants,
        };
      });

      setPackageDates({
        ...packageDates,
        deadline: moment(
          loadPackage?.data?.getPackage?.booking_deadline_date
        ).format("ll"),
        depature: moment(loadPackage?.data?.getPackage?.departure_date).format(
          "ll"
        ),
        return: moment(loadPackage?.data?.getPackage?.return_date).format("ll"),
      });
    }
  }, [loadPackage?.data]);

  useEffect(() => {
    if (loadPackage.data) {
      const Frequency =
        (debit_interval /
          closestNumber(
            getNumberOfDays(
              loadPackage?.data?.getPackage?.booking_deadline_date
            ),
            debit_interval
          )) *
        loadPackage?.data?.getPackage?.rate;

      setPackageFrequencyRate(
        Frequency > loadPackage.data.getPackage.rate
          ? formatter.format(loadPackage.data.getPackage.rate)
          : formatter.format(Frequency)
      );
    }
  }, [createVaultData]);

  function closestNumber(r_days, freq) {
    let q = Math.floor(r_days / freq);

    let n1 = freq * q;

    let n2 = r_days * freq > 0 ? freq * (q + 1) : freq * (q - 1);

    if (Math.abs(r_days - n1) < Math.abs(r_days - n2)) return n1;

    return n2;
  }

  function getNumberOfDays(targetDate) {
    const today = new Date().toJSON();

    const date1 = new Date(moment(today).format("l"));
    const date2 = new Date(moment(targetDate).format("l"));

    const oneDay = 1000 * 60 * 60 * 24;

    const diffInTime = date2.getTime() - date1.getTime();

    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }

  const [userMessage, setUserMessage] = useState("");

  useEffect(() => {
    if (loadPackages) {
      setAllPackages(loadPackages.data?.getPackages?.packages);
    }
    if (loadPackages?.error) {
      warningToast("Warning", "Error in loading packages");
    }
  }, [loadPackages]);

  useEffect(() => {
    if (userSearch.data) {
      if (userSearch.data.searchThroughUsers.length === 0) {
        setUserMessage("empty");
        setLoadBtn(false);
      } else {
        setUserMessage("exists");
        setLoadBtn(false);
        createVaultData.joint_user_ids.push(userSearch.data.searchThroughUsers);
      }
    }
    if (userSearch.error) {
      setUserMessage("error");
      setLoadBtn(false);
    }
  }, [trigger]);

  const savingFrequencies = [
    { name: resolvers.AuthType.CUSTOM, days: "" },
    { name: resolvers.AuthType.DAILY, days: "(1 Day)" },
    { name: resolvers.AuthType.WEEKLY, days: "(7 Days)" },
    { name: resolvers.AuthType.MONTHLY, days: "(30 Days)" },
  ];

  const [createVault, { loading }] = useMutation(TRIPVAULT_MUTATION, {
    refetchQueries: [{ query: LOAD_SAVINGSACCOUNT }],
  });

  const [userIds, setUserIds] = useState([]);

  const createTripVault = () => {
    createVaultData.joint_user_ids.forEach((element) => {
      userIds.push(element[0].id);
    });
    createVault({
      variables: {
        package: createVaultData.package,
        autosave: createVaultData.autosave,
        group_type: createVaultData.group_type,
        debit_interval: parseInt(createVaultData.debit_interval),
        savings_frequency: createVaultData.savingFrequency,
        savings_type: createVaultData.savings_type,
        joint_user_ids: userIds,
        number_adults: createVaultData.number_adults,
        number_children: createVaultData.number_children,
        number_infants: createVaultData.number_infants,
        payment_type: "INSTALLMENT",
      },
    })
      .then((response) => {
        if (response.data.addTripVault.__typename === "BadRequest") {
          warningToast("Warning", response.data.addTripVault.message);
        }
        if (
          response.data.addTripVault.__typename === "AddSavingsAccountSuccess"
        ) {
          successToast("Success", "Vault successfully created");
          setVaultStep(vaultStep + 1);
        }
      })
      .catch((error) => {
        if (createVaultData.package === null) {
          errorToast("Error", "Select a Package");
        } else if (!createVaultData.savingFrequency) {
          errorToast("Error", "Select savings frequency");
        } else {
          errorToast("Error", error ? error?.message : "An error occured");
        }
      });
  };

  function close_reload() {
    onClose();
  }

  function handleSubmit(event) {
    event.preventDefault();
    createTripVault();
  }
  return (
    <>
      {vaultStep === 0 && (
        <div>
          <span
            onClick={onClose}
            className="cursor-pointer absolute top-3 left-3 "
          >
            <Close />
          </span>
          <div className="flex py-8 space-y-8 flex-col">
            <span className="text-blue-200 font-medium text-lg md:text-2xl pl-3">
              Create a vault
            </span>

            <Button
              isBlue
              text="Personal Vault"
              className="text-lg flex flex-row-reverse items-center justify-between py-10 px-6 h-auto font-medium"
              withIcon
              icon={<Arrow stroke="#ffffff" />}
              onClick={handlePersonalVault}
            />
            <Button
              isYellow
              text="Joint Trip Vault"
              className="text-lg flex flex-row-reverse items-center justify-between text-blue-200 font-medium py-10 px-6 h-auto"
              withIcon
              icon={<Arrow stroke="#0C203C" />}
              onClick={handleJointVault}
            />
          </div>
        </div>
      )}

      {vaultType === "PersonalVault" && vaultStep === 1 ? (
        <form action={handleSubmit}>
          <div className="mt-6">
            <span
              onClick={() => setVaultStep(vaultStep - 1)}
              className="cursor-pointer absolute top-3 left-3 "
            >
              <BackArrow />
            </span>
            <div className="flex space-y-4 flex-col">
              <span className="text-blue-200 font-medium text-lg md:text-2xl xs:pl-3 pl-1">
                Create Personal Package Vault
              </span>
              <div className="flex space-y-4 flex-col overflow-y-auto max-h-[calc(100vh_-_120px)]">
                <Datalist
                  label={`${type} Description`}
                  placeholder="Select A Trip"
                  withLabel
                  name={"package"}
                  options={allPackages}
                  type={"text"}
                  list={"data"}
                  onChange={handleChange}
                  value={createVaultData.package}
                  required
                  wrapClass={createVaultData === null ? "border-red-100" : ""}
                />
                {packagePrice && (
                  <div className="pl-4 text-gray-100 text-sm">
                    <p>Deadline : {packageDates.deadline}</p>
                    <p>Depature : {packageDates.depature}</p>
                    <p>Return : {packageDates.return}</p>
                    <p>Target Amount : {packagePrice}</p>
                  </div>
                )}

                <SelectInput
                  label="Select a savings frequency"
                  placeholder={"Anytime"}
                  withLabel
                  name={"savingFrequency"}
                  onChange={handleChange}
                  options={savingFrequencies}
                  value={createVaultData.savingFrequency}
                  required
                  wrapClass={
                    createVaultData.savingFrequency ? "" : "border-red-100"
                  }
                />

                {createVaultData.savingFrequency === "CUSTOM" && (
                  <Input
                    onChange={handleChange}
                    type="number"
                    label={`Debit Interval`}
                    placeholder={"Number of days between debits"}
                    withLabel
                    name="debit_interval"
                    value={createVaultData.debit_interval}
                    days={true}
                    className={
                      createVaultData.debit_interval ? "" : "border-red-100"
                    }
                  />
                )}

                {packagePrice && (
                  <div className="text-black-100 font-medium text-base">
                    <p>Target Amount : {packagePrice}</p>
                  </div>
                )}

                {packageFrequencyRate && (
                  <div className="text-black-100 font-medium text-base">
                    Frequency : {packageFrequencyRate}{" "}
                    <span className="text-blue-100 font-medium text-xs">
                      {createVaultData.savingFrequency}
                    </span>
                  </div>
                )}

                <div>
                  <Button
                    loading={loading}
                    isBlue
                    type="button"
                    text={`Create A ${type} Vault`}
                    className="text-base font-medium mt-10 "
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        vaultType === "PersonalVault" &&
        vaultStep === 2 && (
          <div>
            <span
              onClick={close_reload}
              className="cursor-pointer absolute top-3 left-3 "
            >
              <Close />
            </span>
            <div className="flex space-y-8 flex-col items-center text-center py-16">
              <img src={Success} alt="" className="w-[25%] " />
              <span className="w-[70%] text-blue-200 font-medium text-2xl">
                Package Vault created successfully
              </span>
            </div>
          </div>
        )
      )}

      {vaultType === "JointVault" && vaultStep === 1 ? (
        <div className="mt-6">
          <span
            onClick={() => setVaultStep(vaultStep - 1)}
            className="cursor-pointer absolute top-3 left-3 "
          >
            <BackArrow />
          </span>
          <div className="flex space-y-4 flex-col">
            <span className="text-blue-200 font-medium text-lg md:text-2xl pl-3">
              Create Joint Package Vault
            </span>
            <div className="flex space-y-4 flex-col overflow-y-auto modal_scroll max-h-[calc(100vh_-_120px)]">
              <Datalist
                onChange={handleChange}
                label="Trip Description"
                placeholder="Select A Trip"
                withLabel
                name={"package"}
                options={allPackages}
                type={"text"}
                list={"data"}
                value={createVaultData.package}
                required
                wrapClass={
                  createVaultData.package === null ? "border-red-100" : ""
                }
              />
              {packagePrice && (
                <div className="pl-4 text-gray-100 text-sm">
                  <p>Deadline : {packageDates.deadline}</p>
                  <p>Depature : {packageDates.depature}</p>
                  <p>Return : {packageDates.return}</p>
                  <p>Target Amount : {packagePrice}</p>
                </div>
              )}

              <SelectInput
                label="Select a savings frequency"
                placeholder={"Anytime"}
                withLabel
                name={"savingFrequency"}
                onChange={handleChange}
                options={savingFrequencies}
                value={createVaultData.savingFrequency}
                required
                wrapClass={createVaultData.package ? "" : "border-red-100"}
              />

              {createVaultData.savingFrequency === "CUSTOM" && (
                <Input
                  onClick={handleChange}
                  type="number"
                  label={`Debit Interval`}
                  placeholder={"Number of days between debits"}
                  withLabel
                  name="debit_interval"
                  value={createVaultData.debit_interval}
                  days={true}
                  className={
                    createVaultData.debit_interval ? "" : "border-red-100"
                  }
                />
              )}
              {packagePrice && (
                <div className="text-black-100 font-medium text-base">
                  Target Amount : {packagePrice}
                </div>
              )}

              {packageFrequencyRate && (
                <div className="text-black-100 font-medium text-base">
                  Frequency : {packageFrequencyRate}{" "}
                  <span className="text-blue-100 font-medium text-xs">
                    {createVaultData.savingFrequency}
                  </span>
                </div>
              )}

              <button
                onClick={searchJointUsers}
                className=" font-normal border-dashed cursor-pointer border border-border-deepgray flex items-center justify-center py-2 rounded-3xl text-gray-300 text-base focus:bg-blue-100 focus:bg-opacity-20 mt-2 "
              >
                + Invite Participants
              </button>
              <br />

              {showJointUser && (
                <>
                  <Input
                    inButton
                    onClickBtn={addJointUsers}
                    type="email"
                    placeholder="Input user email"
                    name="jointUsers"
                    value={jointUsers}
                    className=""
                    onChange={isChange}
                    btn_text="Add User"
                    labelClassName={"m-0"}
                    loading={loadBtn}
                  />

                  {userMessage === "empty" ? (
                    <div className="text-sm text-red-100 font-medium">
                      Email is not a user
                    </div>
                  ) : userMessage === "error" ? (
                    <div className="text-sm text-red-100 font-medium">
                      Email does not exist
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="flex flex-row flex-wrap gap-4">
                    {createVaultData.joint_user_ids.map((user, index) => (
                      <RoundButton
                        number={index > 11 ? 0 : index}
                        key={user[0].firstName + index}
                        text={user[0].firstName.charAt(0)}
                        className="mt-2"
                      />
                    ))}
                  </div>
                </>
              )}

              <div>
                <Button
                  isBlue
                  text={`Create A ${type} Vault`}
                  className="text-base font-medium mt-4 "
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        vaultType === "JointVault" &&
        vaultStep === 2 && (
          <div>
            <span
              onClick={close_reload}
              className="cursor-pointer absolute top-3 left-3 "
            >
              <Close />
            </span>
            <div className="flex space-y-8 flex-col items-center text-center py-16">
              <img src={Success} alt="" className="w-[25%] " />
              <span className="w-[70%] text-blue-200 font-medium text-2xl">
                Package Vault created successfully
              </span>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default CreateVault;
