import React, { useState } from "react";
import { ReactComponent as Close } from "../../assets/img/close-modal.svg";
import Success from "../../assets/img/success.png";
import { Button } from "../buttons";
import { ReactComponent as Location } from "assets/icons/blue_location.svg";
import { ReactComponent as Date } from "assets/icons/blue_date.svg";
import { ReactComponent as Rating } from "../../assets/img/rating-input.svg";
import { ReactComponent as Travellers } from "../../assets/img/travellers-input.svg";
import { ReactComponent as Flight } from "../../assets/img/rating.svg";

import {
  CustomDropdown,
  Input,
  SelectWithInput,
  Textarea,
} from "../input/Input";
import { AiOutlineArrowRight } from "react-icons/ai";
import { ADD_CUSTOMTRIP_MUTATION } from "GraphQL/Mutations";
import { useMutation, useQuery } from "@apollo/client";
import { BsArrowLeft } from "react-icons/bs";
import { errorToast, successToast, warningToast } from "components/toast/toast";
import { CUSTOM_TRIP } from "GraphQL/Queries";

const CreateTrip = ({ onClose, handleDateClick }) => {
  const [travellersModal, setTravellersModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [page, setPage] = useState(0);
  const [createTripSuccess, setCreateTripSuccess] = useState(false);

  const [planTrip, setPlanTrip] = useState({
    from_where: "",
    to_where: "",
    departure_date: "",
    return_date: "",
    hotel_rating: 0,
    travellers: 0,
    number_adults: 0,
    number_children: 0,
    number_infants: 0,
    flight_class: "",
    visa_support: false,
    additional_info: "",
  });
  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setPlanTrip((prevPlanTrip) => {
      return {
        ...prevPlanTrip,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }
  const toggleTravellers = () => {
    setTravellersModal(!travellersModal);
  };

  const addTravellers = (type) => {
    setPlanTrip((prevPlanTrip) => {
      return {
        ...prevPlanTrip,
        [type]: planTrip[type] + 1,
      };
    });
    setPlanTrip((prevPlanTrip) => {
      return {
        ...prevPlanTrip,
        travellers: planTrip.travellers + 1,
      };
    });
  };

  const subtractTravellers = (type) => {
    if (planTrip[type] > 0) {
      setPlanTrip((prevPlanTrip) => {
        return {
          ...prevPlanTrip,
          [type]: planTrip[type] - 1,
        };
      });

      if (planTrip.travellers > 0) {
        setPlanTrip((prevPlanTrip) => {
          return {
            ...prevPlanTrip,
            travellers: planTrip.travellers - 1,
          };
        });
      } else {
        setPlanTrip((prevPlanTrip) => {
          return {
            ...prevPlanTrip,
            travellers: 0,
          };
        });
      }
    } else {
      setPlanTrip((prevPlanTrip) => {
        return {
          ...prevPlanTrip,
          [type]: 0,
        };
      });
    }
  };

  const hotel_rating = [
    {
      label: "2 star hotel",
      value: 2,
    },
    {
      label: "3 star hotel",
      value: 3,
    },
    {
      label: "4 star hotel",
      value: 4,
    },
    {
      label: "5 star hotel",
      value: 5,
    },
  ];
  const flight_class = [
    "Economy",
    "Premium Economy",
    "Business",
    "First Class",
  ];

  const [addCustomTrip] = useMutation(ADD_CUSTOMTRIP_MUTATION);

  const { refetch } = useQuery(CUSTOM_TRIP);

  const handleSubmit = () => {
    setSubmitting(true);

    addCustomTrip({
      variables: {
        from_where: planTrip.from_where,
        to_where: planTrip.to_where,
        departure_date: planTrip.departure_date,
        return_date: planTrip.return_date,
        hotel_rating: parseInt(planTrip.hotel_rating),
        number_adults: planTrip.number_adults,
        number_children: planTrip.number_children,
        number_infants: planTrip.number_infants,
        flight_class: planTrip.flight_class,
        visa_support: planTrip.visa_support,
        additional_info: planTrip.additional_info,
      },
    })
      .then((response) => {
         if (response.data.addCustomTrip.__typename === "BadRequest") {
          warningToast("Warning", response.data.addCustomTrip.message);
        }
        if (response.data.addCustomTrip.__typename === "CustomTripSuccess") {
          successToast("Success", "Custom trip created successfully");
          setCreateTripSuccess(true);
          refetch();
        }
      })
      .catch((error) => {
        errorToast("Error", error ? error?.message : "An error occured");
      })
      .finally(() => setSubmitting(false));
  };

  const formFilled = () => {
    return (
      !planTrip?.departure_date ||
      !planTrip?.flight_class ||
      !planTrip?.from_where ||
      !planTrip?.hotel_rating ||
      !planTrip?.return_date ||
      !planTrip?.to_where ||
      !planTrip?.travellers
    );
  };
  return (
    <div className="w-full ">
      <div onClick={onClose}></div>
      {
        <div className="card relative px-2">
          <span
            onClick={onClose}
            className="cursor-pointer absolute top-8 left-3 "
          >
            <Close />
          </span>
          <div className=" mx-auto space-y-8 hidden mdLg:block">
            <div className="flex flex-col gap-y-4 px-2 sm:px-4 md:px-10">
              <h2 className="text-white-100 font-medium text-3xl md:text-4xl pt-3 text-center md:text-left">
                Plan your trip
              </h2>
              <p className="text-white-100 text-lg text-center md:text-left">
                Tell us a bit about your dream trip
              </p>
            </div>

            <form>
              <div className="flex  items-end justify-center xl:justify-start flex-wrap  gap-5 lg:gap-x-14 overflow-auto  max-h-[calc(100vh_-_160px)] modal_scroll px-2 sm:px-4 md:px-10">
                <div className="flex flex-col space-y-5 w-full ">
                  <div className="flex w-full gap-x-4 justify-between flex-1">
                    <Input
                      label={"From where?"}
                      name="from_where"
                      value={planTrip.from_where}
                      onChange={handleChange}
                      placeholder="Airport or city"
                      Icon={<Location />}
                      iconClass="bg-white-100"
                      withLabel
                      type="text"
                      inputClass={" md:w-[40%] w-[80%] "}
                      className={"h-14 px-2"}
                      labelClassName={"text-white-100"}
                    />
                    <Input
                      label={"To where?"}
                      name="to_where"
                      value={planTrip.to_where}
                      onChange={handleChange}
                      placeholder="Airport or city"
                      Icon={<Location />}
                      withLabel
                      iconClass="bg-white-100"
                      type="text"
                      inputClass={" md:w-[40%] w-[80%] "}
                      className={"h-14 px-2"}
                      labelClassName={"text-white-100"}
                    />
                    <Input
                      label={"Leaving on"}
                      name="departure_date"
                      value={planTrip.departure_date}
                      onChange={handleChange}
                      placeholder="Departure Date"
                      Icon={<Date />}
                      withLabel
                      iconClass="bg-white-100"
                      type="text"
                      onFocus={handleDateClick}
                      inputClass={" md:w-[40%] w-[80%] "}
                      className={"h-14 pl-2"}
                      labelClassName={"text-white-100"}
                    />
                    <Input
                      label={"Returning on"}
                      name="return_date"
                      value={planTrip.return_date}
                      onChange={handleChange}
                      placeholder=" Return Date"
                      Icon={<Date />}
                      iconClass="bg-white-100"
                      onFocus={handleDateClick}
                      withLabel
                      type="text"
                      inputClass={" md:w-[40%] w-[80%] "}
                      className={"h-14 pl-2"}
                      labelClassName={"text-white-100"}
                    />{" "}
                  </div>
                  <div className="flex flex-auto flex-grow gap-x-4">
                    <SelectWithInput
                      title={"Preferred hotel rating"}
                      name="hotel_rating"
                      value={planTrip?.hotel_rating}
                      onChange={handleChange}
                      options={hotel_rating}
                      placeholder="Select hotel rating"
                      withLabel
                      className={"h-[56px] w-full pr-2"}
                      inputClass="w-full"
                      labelClassName={"text-white-100"}
                      IconLeft={
                        <Rating
                          width={"20px"}
                          height={"20px"}
                          fill="#1C9FDA"
                          stroke="#1C9FDA"
                        />
                      }
                    />

                    <CustomDropdown
                      label={"Number of travellers "}
                      name="travellers"
                      placeholder="Select travellers"
                      withLabel
                      className={"h-14 flex pl-7 items-center"}
                      inputClass="w-full cursor-pointer"
                      coverClass={"w-[400px]"}
                      modalClass={"-inset-x-[0px] -inset-y-[0px] "}
                      iconClass="left-1 top-1/2 -translate-y-1/2"
                      labelClassName={"text-white-100"}
                      Icon={
                        <Travellers
                          width={"20px"}
                          height={"20px"}
                          fill="#1C9FDA"
                          stroke="#1C9FDA"
                        />
                      }
                      toggleTravel={travellersModal}
                      toggleTravelModal={toggleTravellers}
                      addAdults={() => addTravellers("number_adults")}
                      addChildren={() => addTravellers("number_children")}
                      addInfants={() => addTravellers("number_infants")}
                      subtractAdults={() => subtractTravellers("number_adults")}
                      subtractChildren={() =>
                        subtractTravellers("number_children")
                      }
                      subtractInfants={() =>
                        subtractTravellers("number_infants")
                      }
                      total_travellers={planTrip.travellers}
                      adults={planTrip.number_adults}
                      children={planTrip.number_children}
                      infants={planTrip.number_infants}
                    />

                    <SelectWithInput
                      title={"Preferred flight class"}
                      name="flight_class"
                      value={planTrip?.flight_class}
                      onChange={handleChange}
                      options={flight_class}
                      placeholder="Select flight class"
                      withLabel
                      className={"h-[56px] pr-2"}
                      inputClass="w-full"
                      labelClassName={"text-white-100"}
                      IconLeft={
                        <Flight
                          width={"20px"}
                          height={"20px"}
                          stroke="#1C9FDA"
                        />
                      }
                    />
                  </div>
                </div>

                <div className="flex flex-col w-full gap-y-6">
                  <Textarea
                    label={"Additional Information"}
                    name="additional_info"
                    value={planTrip.additional_info}
                    onChange={handleChange}
                    placeholder="Tell us what you might need for your dream trip"
                    type="textfield"
                    withLabel
                    inputClass={"w-full "}
                    className="h-32 p-2"
                    labelClassName={"text-white-100"}
                  />

                  <div className="flex gap-x-3">
                    <input
                      type="checkbox"
                      id="visa_support"
                      name="visa_support"
                      checked={planTrip.visa_support}
                      onChange={handleChange}
                      className="accent-blue-200 w-6 h-6"
                      labelClassName={"text-white-100"}
                    />
                    <label
                      className="text-white-100 font-medium text-lg"
                      htmlFor="visa_support"
                    >
                      I need support for visa
                    </label>
                    <br />
                  </div>
                </div>

                <div className="my-2 mx-auto md:ml-auto md:mx-0 w-full ">
                  <Button
                    text={"Get Trip Estimate"}
                    onClick={() => {
                      handleSubmit();
                    }}
                    type={"button"}
                    RightIcon
                    isYellow
                    loading={submitting}
                    disabled={formFilled()}
                    icon={<AiOutlineArrowRight color="#000000" />}
                    className="flex gap-4 items-center justify-center font-medium  "
                  />
                </div>
              </div>
            </form>
          </div>

          {page === 0 && (
            <div className=" mx-auto space-y-6 mdLg:hidden block">
              <div className="flex flex-col gap-y-2 px-2 sm:px-6 md:px-10">
                <h2 className="text-white-100 font-medium text-xl md:text-4xl pt-2 text-center md:text-left">
                  Plan your trip
                </h2>
                <p className="text-white-100 text-base text-center md:text-left">
                  Tell us a bit about your dream trip
                </p>
                <div className="flex flex-col xs:flex-row gap-x-4 gap-y-2">
                  <p onClick={() => setPage(0)} className="flex gap-x-2">
                    <p
                      className={`${
                        page === 0
                          ? "bg-[#136A91] text-white-100"
                          : "bg-[#D2ECF8] text-blue-100"
                      }  w-5 h-5 rounded-full  flex items-center justify-center text-xs font-medium`}
                    >
                      1
                    </p>
                    <p className="text-white-100 ">Flight details</p>
                  </p>
                  <p onClick={() => setPage(1)} className="flex gap-x-2">
                    <p
                      className={`${
                        page === 1
                          ? "bg-[#136A91] text-white-100"
                          : "bg-[#D2ECF8] text-blue-100"
                      } bg-[#D2ECF8] text-blue-100 w-5 h-5 rounded-full  flex items-center justify-center text-xs font-medium`}
                    >
                      2
                    </p>
                    <p className="text-white-100/60">Trip preferences</p>
                  </p>
                </div>
              </div>

              <form>
                <div className="flex  items-end  flex-wrap  gap-5 lg:gap-x-14 overflow-auto px-2 sm:px-6 md:px-10 max-h-[calc(90vh_-_160px)] modal_scroll">
                  <div className="flex flex-col space-y-3 w-full justify-center sm:px-0 px-4">
                    <div className="flex sm:flex-row flex-col sm:gap-y-0 gap-y-2 w-full gap-x-8 justify-between flex-1">
                      <Input
                        label={"From where?"}
                        name="from_where"
                        value={planTrip.from_where}
                        onChange={handleChange}
                        placeholder="Airport or city"
                        Icon={<Location />}
                        withLabel
                        type="text"
                        inputClass={"xl:w-1/5 mdLg:w-[40%] w-full "}
                        className={"h-10 sm:h-14 px-2"}
                        labelClassName={"text-white-100"}
                      />
                      <Input
                        label={"To where?"}
                        name="to_where"
                        value={planTrip.to_where}
                        onChange={handleChange}
                        placeholder="Airport or city"
                        Icon={<Location />}
                        withLabel
                        type="text"
                        inputClass={"xl:w-1/5 mdLg:w-[40%] w-full "}
                        className={"h-10 sm:h-14 px-2"}
                        labelClassName={"text-white-100"}
                      />
                    </div>
                    <div className="flex sm:flex-row flex-col sm:gap-y-0 gap-y-2 w-full gap-x-8 justify-between flex-1">
                      <Input
                        label={"Leaving on"}
                        name="departure_date"
                        value={planTrip.departure_date}
                        onChange={handleChange}
                        placeholder="Departure Date"
                        Icon={<Date />}
                        withLabel
                        type="text"
                        onFocus={handleDateClick}
                        inputClass={"xl:w-1/5 mdLg:w-[40%] w-full "}
                        className={"h-10 sm:h-14 px-2"}
                        labelClassName={"text-white-100"}
                      />
                      <Input
                        label={"Returning on"}
                        name="return_date"
                        value={planTrip.return_date}
                        onChange={handleChange}
                        placeholder=" Return Date"
                        Icon={<Date />}
                        onFocus={handleDateClick}
                        withLabel
                        type="text"
                        inputClass={"xl:w-1/5 mdLg:w-[40%] w-full "}
                        className={"h-10 sm:h-14 px-2"}
                        labelClassName={"text-white-100"}
                      />{" "}
                    </div>
                  </div>

                  <div className="flex gap-x-3 items-center relative w-full">
                    <input
                      type="checkbox"
                      id="visa_support"
                      name="visa_support"
                      checked={planTrip.visa_support}
                      onChange={handleChange}
                      className="accent-blue-200 w-4 h-4 absolute top-1/2 bottom-1/2 -translate-y-1/2"
                      labelClassName={"text-white-100"}
                    />
                    <label
                      className="text-white-100 font-medium text-base absolute left-6 "
                      htmlFor="visa_support"
                    >
                      I need support for visa
                    </label>
                    <br />
                  </div>

                  <div className="my-2 mx-auto md:ml-auto md:mx-0 w-full ">
                    <Button
                      text={"Proceed to Trip preferences"}
                      onClick={() => {
                        setPage(1);
                      }}
                      type={"button"}
                      RightIcon
                      isYellow
                      icon={<AiOutlineArrowRight color="#000000" />}
                      className="flex gap-4 items-center justify-center font-medium  "
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
          {page === 1 && (
            <div className=" mx-auto space-y-6 mdLg:hidden block">
              <div className="flex flex-col gap-y-2 px-2 sm:px-6 md:px-10">
                <p
                  onClick={() => {
                    setPage(0);
                  }}
                  className="flex justify-end gap-x-3 items-center text-white-100/90 pt-4"
                >
                  <span>
                    <BsArrowLeft className="text-white-100" />
                  </span>
                  <p>Go back</p>
                </p>
                <h2 className="text-white-100 font-medium text-xl md:text-4xl text-center md:text-left">
                  Plan your trip
                </h2>
                <p className="text-white-100 text-base text-center md:text-left">
                  Tell us a bit about your dream trip
                </p>
                <div className="flex flex-col xs:flex-row gap-x-4 gap-y-2">
                  <p onClick={() => setPage(0)} className="flex gap-x-2">
                    <p
                      className={`${
                        page === 0
                          ? "bg-[#136A91] text-white-100"
                          : "bg-[#D2ECF8] text-blue-100"
                      }  w-5 h-5 rounded-full  flex items-center justify-center text-xs font-medium`}
                    >
                      1
                    </p>
                    <p className="text-white-100/60 ">Flight details</p>
                  </p>
                  <p onClick={() => setPage(1)} className="flex gap-x-2">
                    <p
                      className={`${
                        page === 1
                          ? "bg-[#136A91] text-white-100"
                          : "bg-[#D2ECF8] text-blue-100"
                      } w-5 h-5 rounded-full  flex items-center justify-center text-xs font-medium`}
                    >
                      2
                    </p>
                    <p className="text-white-100">Trip preferences</p>
                  </p>
                </div>
              </div>

              <form>
                <div className="flex items-end  flex-wrap  gap-5 lg:gap-x-14 overflow-auto px-2 sm:px-6 md:px-10  max-h-[calc(90vh_-_200px)] modal_scroll">
                  <div className="flex flex-col space-y-3 w-full justify-center sm:px-0 px-4">
                    <div className="flex sm:flex-row flex-col sm:gap-y-0 gap-y-2 w-full gap-x-8 justify-between flex-1">
                      <SelectWithInput
                        title={"Preferred hotel rating"}
                        name="hotel_rating"
                        value={planTrip?.hotel_rating}
                        onChange={handleChange}
                        options={hotel_rating}
                        placeholder="Select hotel rating"
                        withLabel
                        className={"h-[40px] sm:h-14 w-full"}
                        iconClass={"h-[40px] sm:h-14 "}
                        inputClass="w-full"
                        labelClassName={"text-white-100"}
                        IconLeft={
                          <Rating
                            width={"20px"}
                            height={"20px"}
                            fill="#1C9FDA"
                            stroke="#1C9FDA"
                          />
                        }
                      />

                      <CustomDropdown
                        label={"Number of travellers"}
                        name="travellers"
                        placeholder="Select travellers"
                        withLabel
                        className={"h-10 sm:h-14 flex pl-7 items-center"}
                        inputClass="w-full drop-shadow-md cursor-pointer "
                        coverClass={"sm:max-w-screen-sm max-w-[130%]"}
                        modalClass={
                          "-translate-x-1/2 -translate-y-1/2 -inset-y-[0px]"
                        }
                        iconClass="left-1 top-1/2 -translate-y-1/2"
                        labelClassName={"text-white-100"}
                        Icon={
                          <Travellers
                            width={"20px"}
                            height={"20px"}
                            fill="#1C9FDA"
                            stroke="#1C9FDA"
                          />
                        }
                        toggleTravel={travellersModal}
                        toggleTravelModal={toggleTravellers}
                        addAdults={() => addTravellers("number_adults")}
                        addChildren={() => addTravellers("number_children")}
                        addInfants={() => addTravellers("number_infants")}
                        subtractAdults={() =>
                          subtractTravellers("number_adults")
                        }
                        subtractChildren={() =>
                          subtractTravellers("number_children")
                        }
                        subtractInfants={() =>
                          subtractTravellers("number_infants")
                        }
                        total_travellers={planTrip.travellers}
                        adults={planTrip.number_adults}
                        children={planTrip.number_children}
                        infants={planTrip.number_infants}
                        smallScreen
                      />

                      <SelectWithInput
                        title={"Preferred flight class"}
                        name="flight_class"
                        value={planTrip?.flight_class}
                        onChange={handleChange}
                        options={flight_class}
                        placeholder="Select flight class"
                        withLabel
                        className={"h-[40px] sm:h-14 "}
                        iconClass={"h-[40px] sm:h-14 "}
                        inputClass="w-full"
                        labelClassName={"text-white-100"}
                        IconLeft={
                          <Flight
                            width={"20px"}
                            height={"20px"}
                            stroke="#1C9FDA"
                          />
                        }
                      />
                    </div>{" "}
                    <div className=" flex flex-col w-full justify-between ">
                      <Textarea
                        label={"Additional Information"}
                        name="additional_info"
                        value={planTrip.additional_info}
                        onChange={handleChange}
                        placeholder="Tell us what you might need for your dream trip"
                        type="textfield"
                        withLabel
                        inputClass={"w-full "}
                        className="h-32 p-2"
                        labelClassName={"text-white-100"}
                      />
                    </div>
                  </div>

                  <div className="my-2 mx-auto md:ml-auto md:mx-0 w-full ">
                    <Button
                      text={"Get Trip Estimate"}
                      onClick={() => {
                        handleSubmit();
                      }}
                      type={"button"}
                      RightIcon
                      isYellow
                      loading={submitting}
                      disabled={formFilled()}
                      icon={<AiOutlineArrowRight color="#000000" />}
                      className="flex gap-4 items-center justify-center font-medium  "
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      }

      {createTripSuccess && (
        <div
          id="modal-backdrop"
          className={`fixed w-[100%] h-[100%] top-0 left-0 z-[100000] grid place-content-center bg-black-tranparent`}
          onClick={() => {
            setCreateTripSuccess(false);
          }}
        >
          <div className="rounded-lg fixed top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 box-border w-[400px] max-w-[calc(100%_-_80px)] bg-white-100 p-6  z-80">
            <span
              onClick={() => {
                setCreateTripSuccess(false);
              }}
              className="cursor-pointer absolute top-3 left-3 "
            >
              <Close />
            </span>
            <div className="flex space-y-4 flex-col items-center text-center justify-center font-medium">
              <img
                src={Success}
                alt=""
                className="sm:w-[30%] xs:w-[35%] w-[50%] mb-4 "
              />
              <span className="mx-auto text-blue-200 font-medium sm:text-2xl text-xl ">
                Trip request successful
              </span>
              <span className="text-gray-100 w-[80%] text-xs sm:text-base">
                You have successfully sent a request for custom trip, an
                estimate would be sent to your email address shortly.
              </span>
              <Button
                onClick={() => {
                  setCreateTripSuccess(false);
                  onClose();
                }}
                isBlue
                text={"Go back to dashboard"}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateTrip;
