import React from "react";
import { BiLoaderCircle } from "react-icons/bi";
import { motion } from "framer-motion";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

const Button = ({
  withIcon,
  RightIcon,
  icon,
  text,
  onClick,
  isBlue,
  isGreen,
  isRed,
  style,
  isYellow,
  className,
  loading,
  type,
  disabled,
  variants,
}) => {
  return (
    <motion.button
      variants={variants}
      style={style}
      className={`${className} w-full h-12 rounded-8 focus:outline-none   
      ${
        isBlue
          ? "hover:bg-opacity-80 text-white-100 disabled:bg-opacity-50 focus:bg-opacity-80 bg-blue-100 "
          : isRed
          ? "hover:bg-red-100 text-white-100 bg-red-100 focus:bg-opacity-80"
          : isYellow
          ? "hover:bg-opacity-80 text-white-100 focus:bg-opacity-80 disabled:bg-opacity-30 bg-gold-100"
          : isGreen
          ? "hover:bg-opacity-90 text-white-100 focus:bg-opacity-90 bg-green-100 "
          : "bg-white-100 text-blue-100 hover:bg-blue-50  focus:bg-opacity-80 "
      } `}
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
    >
      {withIcon && <span className="mr_10">{icon}</span>}
      {loading ? (
        <span className="">
          <BiLoaderCircle size={32} className="mx-auto animate-spin-slow" />
        </span>
      ) : !loading ? (
        <div className="ml_10">{text}</div>
      ) : null}
      {RightIcon && <span className="mr_10">{icon}</span>}
    </motion.button>
  );
};

const RoundButton = ({ onClick, className, text, number }) => {
  const colors = [
    "#1C9FDA",
    "#3a0891",
    "#007175",
    "#b22f0e",
    "#8c0737",
    "#ce6c10",
    "#69079e",
    "#d615af",
    "#ba0fe0",
    "#e812a0",
    "#FEBB02",
  ];

  const styles = {
    backgroundColor: colors[number],
  };

  return (
    <button
      style={styles}
      className={`rounded-full w-14 h-14 bg-blue-100 text-white-100 capitalize text-2xl font-medium ${className}`}
      onClick={onClick}
    >
      <span className="">{text}</span>
    </button>
  );
};

const ArithmeticBtn = ({
  deepBlue,
  lightBlue,
  gray,
  add,
  subtract,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={`w-[48px] aspect-square flex items-center justify-center rounded-lg cursor-pointer
      ${deepBlue && "bg-blue-100"} ${lightBlue && "bg-[#D2ECF8]"} ${
        gray && "bg-[#EAECF0]/40 focus:opacity-50 "
      }`}
    >
      {add && (
        <p className="flex items-center justify-center">
          <AiOutlinePlus
            style={{
              strokeWidth: "60",
            }}
            color={
              (deepBlue && "#ffffff") ||
              (lightBlue && "#121212") ||
              (gray && "#121212")
            }
          />
        </p>
      )}
      {subtract && (
        <p className="flex items-center justify-center">
          <AiOutlineMinus
            style={{
              strokeWidth: "60",
            }}
            color={
              (deepBlue && "#ffffff") ||
              (lightBlue && "#121212") ||
              (gray && "#121212")
            }
          />
        </p>
      )}
    </div>
  );
};

const RadioBtn = ({ selected, onClick, text, disabled, textClass }) => {
  return (
    <div
      onClick={disabled ? () => {} : onClick}
      className={`bg-[#F2F4F7] rounded-lg w-full p-4 flex items-center gap-x-2 cursor-pointer ${
        selected && "border border-blue-100"
      }`}
    >
      <div className="border-2 border-blue-100 rounded-full p-1">
        <p
          className={`w-3 aspect-square rounded-full ${
            selected ? "bg-blue-100" : ""
          }`}
        ></p>
      </div>
      <div className={`${textClass} font-medium text-blue-200`}>{text}</div>
    </div>
  );
};

export { Button, RoundButton, ArithmeticBtn, RadioBtn };
