import React, { useEffect, useState } from "react";
import { images } from "constants";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";

const Frame1 = ({ setIsCustom }) => {
  const btn = [{ id: 1 }, { id: 2 }];
  const [isBlue, setIsBlue] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsBlue(true);
    }, 2000);
    setTimeout(() => {
      setIsCustom(true);
    }, 4000);
  }, []);

  return (
    <div className="xs:my-[20px] lg:my-[40px] xs:w-full sm:w-auto lg:min-w-[82%] lg:h-fit overflow-hidden xs:h-[420px] relative bg-[white] rounded-[8px] px-[24px] py-[30px]">
     <div className="absolute z-[1001] w-[100%] h-[50%] lg:top-0 xs:bottom-0 left-0 grid place-content-center overlay" />
      <div className="flex xs:gap-[10px] sm:gap-0 sm:flex-row xs:flex-col xs:items-start md:items-center justify-between w-full">
        <div>
          <div className="text-dark text-[17px] font-bold">Packages</div>
          <div className="text-[13px] font-medium text-light">
            Travel packages made for you
          </div>
        </div>

        <button className="bg-red text-[15px] px-[18px] py-[11px] text-[white] bg-blue-100 rounded-[8px]">
          Create custom tip
        </button>
      </div>
      <div className={`cursor  ${isBlue ? "cursor2" : "cursor1"} `}>
        <img src={images.cursor} />
      </div>
      <div className="flex xs:flex-col sm:flex-row gap-[15px] justify-between xs:items-start sm:items-center w-full">
        {btn.map((data, index) => (
          <div key={data.id} className="mb-[16px] w-full">
            <div className="flex w-full flex-col mt-[20px] pb-[26px] min-h-[298px]">
              <div className="bg-[#EDEDED] w-full min-h-[149px] rounded-t-[8px]">
                <div className="flex justify-end items-center">
                  <button className="bg-[white] m-[10px] rounded-full p-[12px]">
                    {isBlue && index === 0 ? (
                      <MdFavorite
                        color="#1C9FDA"
                        size="22px"
                        cursor="pointer"
                      />
                    ) : (
                      <MdFavoriteBorder
                        color="#d9d9d9"
                        size="22px"
                        cursor="pointer"
                      />
                    )}
                  </button>
                </div>
              </div>

              <div className="bg-[#f9f9f9] w-full pt-[20px]  rounded-b-[8px] min-h-[129px]">
                {new Array(3).fill(0).map((_, i) => (
                  <div key={i} className="mb-[12px]">
                    {new Array(1).fill(0).map((_, i) => (
                      <div
                        key={i}
                        className="mx-[10px] bg-[#e3e3e3] opacity-[0.2] px-[100px] py-[11px] rounded-full"
                      />
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Frame1;
