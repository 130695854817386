import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation login($email: Email!, $password: String!) {
    login(email: $email, password: $password) {
      __typename
      ... on UserLoginResultSuccess {
        token
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation register(
    $firstName: String!
    $lastName: String!
    $email: Email!
    $phoneNumber: String!
    $password: String!
    $account_type: AccountType!
    $referralCode: String!
  ) {
    register(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      password: $password
      account_type: $account_type
      referralCode: $referralCode
    ) {
      __typename
      ... on UserRegisterResultSuccess {
        token
      }
      ... on BadRequest {
        message
      }
    }
  }
`;
export const OAUTH_SIGNUP_MUTATION = gql`
  mutation signUpWithOauth(
    $firstName: String!
    $lastName: String!
    $email: Email!
    $account_type: AccountType!
    $referralCode: String
    $oauthId: String!
    $oauthType: OAuthType!
  ) {
    signUpWithOauth(
      firstName: $firstName
      lastName: $lastName
      email: $email
      account_type: $account_type
      referralCode: $referralCode
      oauthId: $oauthId
      oauthType: $oauthType
    ) {
      __typename
      ... on UserRegisterResultSuccess {
        token
      }
      ... on BadRequest {
        message
      }
    }
  }
`;
export const OAUTH_SIGNIN_MUTATION = gql`
  mutation signUpWithOauth(
    $firstName: String!
    $lastName: String!
    $email: Email!
    $referralCode: String
    $oauthId: String!
    $oauthType: OAuthType!
  ) {
    signUpWithOauth(
      firstName: $firstName
      lastName: $lastName
      email: $email
      referralCode: $referralCode
      oauthId: $oauthId
      oauthType: $oauthType
    ) {
      __typename
      ... on UserRegisterResultSuccess {
        token
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const UPDATE_PROFILE_MUTATION = gql`
  mutation updateUser(
    $firstName: String!
    $lastName: String!
    $images: [String!]
    $phoneNumber: String!
  ) {
    updateUser(
      firstName: $firstName
      lastName: $lastName
      images: $images
      phoneNumber: $phoneNumber
    ) {
      __typename
      ... on UserUpdateResultSuccess {
        user {
          id
        }
      }
      ... on BadRequest {
        message
      }
    }
  }
`;
export const UPDATE_PASSWORD_MUTATION = gql`
  mutation updateUser($password: String!) {
    updateUser(password: $password) {
      __typename
      ... on UserUpdateResultSuccess {
        user {
          id
        }
      }
      ... on BadRequest {
        message
      }
    }
  }
`;
export const VERIFY_USER_MUTATION = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token) {
      __typename
      ... on Success {
        message
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation forgotPassword($email: Email!) {
    forgotPassword(email: $email)
  }
`;

export const CONFIRM_PASS_OTP = gql`
  mutation confirmResetPasswordOTP($email: Email!, $otp: String!) {
    confirmResetPasswordOTP(email: $email, otp: $otp) {
      __typename
      ... on Success {
        message
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const RESET_PASS = gql`
  mutation resetPassword(
    $email: Email!
    $password: String!
    $confirmPassword: String!
  ) {
    resetPassword(
      email: $email
      password: $password
      confirmPassword: $confirmPassword
    ) {
      __typename
      ... on Success {
        message
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const TRIPVAULT_MUTATION = gql`
  mutation addTripVault(
    $package: ID
    $autosave: Boolean!
    $savings_type: SavingsType!
    $group_type: GroupType!
    $target_date: Date
    $debit_interval: Int
    $savings_frequency: SavingsFrequency
    $room_rate_id: ID
    $user_id: ID
    $joint_user_ids: [String]
    $number_adults: Int
    $number_children: Int
    $number_infants: Int
    $number_packages: Int!
    $payment_type: PaymentType!
    $couponCode: String
  ) {
    addTripVault(
      package: $package
      autosave: $autosave
      savings_type: $savings_type
      group_type: $group_type
      target_date: $target_date
      debit_interval: $debit_interval
      savings_frequency: $savings_frequency
      room_rate_id: $room_rate_id
      user_id: $user_id
      joint_user_ids: $joint_user_ids
      number_adults: $number_adults
      number_children: $number_children
      number_infants: $number_infants
      number_packages: $number_packages
      payment_type: $payment_type
      couponCode: $couponCode
    ) {
      __typename
      ... on AddSavingsAccountSuccess {
        savingsAccount {
          id
          name
          balance
          savings_frequency
          debit_interval
          autosave
          savings_type
          group_type
          savings_goal
          amount_to_charge
          next_debit_date
          primary_card {
            id
            card_name
            token
            status
            last_four_digit
            country_code
          }
          target_date
          number_adults
          number_children
          number_infants
        }
      }
      ... on BadRequest {
        message
      }
    }
  }
`;
export const DEPOSIT_ALL_MUTATION = gql`
  mutation addTripVault(
    $package: ID
    $savings_type: SavingsType!
    $group_type: GroupType!
    $room_rate_id: ID
    $autosave: Boolean!
    $number_adults: Int
    $number_children: Int
    $number_infants: Int
    $number_packages: Int!
    $payment_type: PaymentType!
    $couponCode: String
  ) {
    addTripVault(
      package: $package
      savings_type: $savings_type
      group_type: $group_type
      room_rate_id: $room_rate_id
      autosave: $autosave
      number_adults: $number_adults
      number_children: $number_children
      number_infants: $number_infants
      number_packages: $number_packages
      payment_type: $payment_type
      couponCode: $couponCode
    ) {
      __typename
      ... on AddSavingsAccountSuccess {
        savingsAccount {
          id
          name
          balance
          savings_frequency
          debit_interval
          autosave
          savings_type
          group_type
          savings_goal
          amount_to_charge
          next_debit_date
          primary_card {
            id
            card_name
            token
            status
            last_four_digit
            country_code
          }
          target_date
          number_adults
          number_children
          number_infants
        }
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const ADD_CUSTOMTRIP_MUTATION = gql`
  mutation addCustomTrip(
    $from_where: String!
    $to_where: String!
    $departure_date: Date!
    $return_date: Date!
    $hotel_rating: Int!
    $number_adults: Int!
    $number_children: Int
    $number_infants: Int
    $flight_class: String!
    $visa_support: Boolean
    $tour_trip: Boolean!
    $additional_info: String
  ) {
    addCustomTrip(
      from_where: $from_where
      to_where: $to_where
      departure_date: $departure_date
      return_date: $return_date
      hotel_rating: $hotel_rating
      number_adults: $number_adults
      number_children: $number_children
      number_infants: $number_infants
      flight_class: $flight_class
      visa_support: $visa_support
      tour_trip: $tour_trip
      additional_info: $additional_info
    ) {
      __typename
      ... on CustomTripSuccess {
        customTrip {
          id
        }
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const TRIP_WISHLIST_MUTATION = gql`
  mutation addFavourite($trip: ID) {
    addFavourite(trip: $trip) {
      __typename
      ... on AddFavouriteSuccess {
        favourite {
          trip {
            name
          }
        }
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const PACKAGE_WISHLIST_MUTATION = gql`
  mutation addFavourite($package: ID) {
    addFavourite(package: $package) {
      __typename
      ... on AddFavouriteSuccess {
        favourite {
          package {
            name
          }
        }
      }
      ... on BadRequest {
        message
      }
    }
  }
`;
export const DELETE_WISHLIST = gql`
  mutation {
    deleteAllFavourites
  }
`;

export const MARK_TRIP_AS_READ = gql`
  mutation markAsRead($custom_trip_id: ID!) {
    markAsRead(custom_trip_id: $custom_trip_id) {
      __typename
      ... on CustomTripSuccess {
        customTrip {
          id
        }
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const AUTOSAVE_UPDATE_MUTATION = gql`
  mutation updateTripVaultAutosave(
    $trip_vault_id: ID!
    $autosave: Boolean!
    $primary_card: ID
  ) {
    updateTripVaultAutosave(
      trip_vault_id: $trip_vault_id
      autosave: $autosave
      primary_card: $primary_card
    ) {
      __typename
      ... on UpdateTripVaultAutosaveSuccess {
        savingsAccount {
          name
          balance
        }
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const FREQUENCY_UPDATE_MUTATION = gql`
  mutation updateTripVaultSavingsFrequency(
    $trip_vault_id: ID!
    $savings_frequency: SavingsFrequency!
  ) {
    updateTripVaultSavingsFrequency(
      trip_vault_id: $trip_vault_id
      savings_frequency: $savings_frequency
    ) {
      name
      autosave
      id
    }
  }
`;

export const SELECT_PRIMARY_CARD_MUTATION = gql`
  mutation updateTripVaultPrimaryCard($trip_vault_id: ID!, $primary_card: ID!) {
    updateTripVaultPrimaryCard(
      trip_vault_id: $trip_vault_id
      primary_card: $primary_card
    ) {
      name
      autosave
      id
    }
  }
`;

export const TRANSFER_FUNDS_MUTATION = gql`
  mutation transfer(
    $source_current_account_id: ID!
    $transfer_amount: String!
    $destination_savings_account_id: ID!
    $user_id: ID!
  ) {
    transfer(
      source_current_account_id: $source_current_account_id
      transfer_amount: $transfer_amount
      destination_savings_account_id: $destination_savings_account_id
      user_id: $user_id
    ) {
      __typename
      ... on TransferResultSuccess {
        savingsAccount {
          name
          balance
        }
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const SEND_OTP = gql`
  mutation {
    createOtp
  }
`;

export const PAY_WITH_CARD_MUTATION = gql`
  mutation verifyOtp(
    $otp: String!
    $token: String!
    $amount: Int!
    $transaction_type: String!
    $account_id: String!
  ) {
    verifyOtp(
      otp: $otp
      token: $token
      amount: $amount
      transaction_type: $transaction_type
      account_id: $account_id
    ) {
      __typename
      ... on Success {
        message
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const WIRE_TRANSFER = gql`
  mutation createWireTransfer(
    $savings_account_id: ID
    $current_account_id: ID
    $transaction_type: TransactionType!
  ) {
    createWireTransfer(
      savings_account_id: $savings_account_id
      current_account_id: $current_account_id
      transaction_type: $transaction_type
    ) {
      __typename
      ... on Success {
        message
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const TRANSFER_CREDIT = gql`
  mutation transferTravelCreditToCurrentAccount(
    $amount: Int!
    $current_account_id: ID!
  ) {
    transferTravelCreditToCurrentAccount(
      amount: $amount
      current_account_id: $current_account_id
    ) {
      __typename
      ... on Success {
        message
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const REDEEM_COUPON = gql`
  mutation redeemCoupon($couponCode: String!) {
    redeemCoupon(couponCode: $couponCode) {
      __typename
      ... on Success {
        message
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const STRIPE_PAYMENT = gql`
  mutation stripePaymentIntent(
    $userId: ID!
    $amount: Int!
    $initiated_by: TransactionInitiatedBy!
    $paymentDetails: PaymentDetailsInput!
  ) {
    stripePaymentIntent(
      userId: $userId
      amount: $amount
      initiated_by: $initiated_by
      paymentDetails: $paymentDetails
    ) {
      __typename
      ... on PaymentIntentSuccess {
        clientSecret
      }
      ... on BadRequest {
        message
      }
    }
  }
`;

export const DELETE_TRIP_MUTATION = gql`
  mutation deleteTripVault($trip_vault_id: ID!) {
    deleteTripVault(trip_vault_id: $trip_vault_id)
  }
`;

export const APPROVE_CUSTOM_TRIP_MUTATION = gql`
  mutation approveTrip($custom_trip_id: ID!, $payment_type: PaymentType!) {
    approveTrip(custom_trip_id: $custom_trip_id, payment_type: $payment_type) {
      __typename
      ... on Success {
        message
      }
      ... on BadRequest {
        message
      }
    }
  }
`;
