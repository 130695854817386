import React, { useEffect, useState } from "react";
import { images } from "constants";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { Warn } from "assets/img/Multi";
import { Button } from "components/buttons";
import TripVaultModal from "components/modal/TripVaultModal";
import TripSuccessModal from "components/modal/TripSuccessModal";

const Frame3 = ({ setSecDone, secDone }) => {
  const [isBlue, setIsBlue] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [create, setCreate] = useState(true);
  const [per, setPer] = useState(false);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setisDone] = useState(false);
  const [amt, setAmt] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsBlue(true);
    }, 1300);
  }, []);

  useEffect(() => {
    if (isBlue) {
      setTimeout(() => {
        setShowModal(true);
        setIsBlue(false);
      }, 1300);
    }
  }, [isBlue]);

  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        setCreate(false);
        setPer(true);
      }, 1300);
    }
  }, [showModal]);

  useEffect(() => {
    if (amt) {
      setTimeout(() => {
        setShowModal(false);
        setIsLoading(true);
      }, 1000);
    }
  }, [amt]);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
        setisDone(true);
      }, 3000);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isDone) {
      setSecDone(true);
    }
  }, [isDone]);

  const accType = sessionStorage.getItem("account_type");

  return (
    <div
      className={`relative xs:my-[20px] lg:my-[40px] xs:w-full sm:w-[75%] md:w-[60%] lg:w-[75%] h-[420px] bg-[white] pt-[10px] rounded-[8px] px-[24px] py-[30px]`}
    >
      <div className="absolute z-[1001] w-[100%] h-[50%] lg:top-[-12px] xs:bottom-[-25px] left-0 grid place-content-center overlay" />
      {isLoading && (
        <div className="absolute rounded-[8px] w-[100%] h-[100%] top-0 left-0 z-[1000] grid place-content-center gradient" />
      )}
      <div className="flex xs:gap-[15px] sm:gap-[20px] justify-between w-full items-center">
        {["Single Occupency", "Double Occupency"].map((data, i) => (
          <div
            key={i}
            className={`font-medium xs:text-[13px] sx:text-[16px] w-full flex xs:gap-[4px] sm:gap-[12px] items-center ${
              i === 0
                ? "text-[#093549] border-blue-100 pb-[8px] border-b-[2px]"
                : "text-[#667085]"
            }`}
          >
            <div>{data}</div>
            <Warn fill={i === 0 ? "#093549" : "#667085"} />
          </div>
        ))}
      </div>
      {!isDone && !isLoading && (
        <div
          className={`cursor ${
            !showModal
              ? "cursor5"
              : showModal && !show && !amt
              ? "cursor6"
              : show
              ? "cursor7"
              : amt && "cursor8"
          }`}
        >
          <img src={images.cursor} />
        </div>
      )}

      {isLoading && (
        <div className="stage z-50">
          <div class="load z-50" />
        </div>
      )}

      <img className="mt-[10px] w-[200px]" src={images.bars} />

      <div className="border-t mt-[10px] pt-[10px] border-[#e0e0e0]">
        <div className="text-[#344054]">TOTAL PRICE</div>
        <div className="text-[#093549] ">
          <span className="font-bold text-[18px] ">
            {accType?.includes("NIGERIAN") ? "₦" : "$"}{" "} 2,100,000
          </span>{" "}
          (per person sharing)
        </div>

        <div className="w-full h-[70px] bg-[#EDEDED] rounded-[8px] mt-[12px]" />
        <div className="flex gap-[30px] items-center">
          <Button
            className={`${
              isBlue && "bg-[#E9F6FC]"
            } border-2 xs:text-[14px] lg:text-[16px] rounded-lg border-blue-100 h-[56px] font-medium w-[50%]`}
            text="Save for this trip"
          />

          <Button
            text="Back now"
            isBlue
            className="font-medium lg:text-[16px] xs:text-[14px] h-[56px] w-[50%]"
          />
        </div>
      </div>
      <TripVaultModal
        create={create}
        amt={amt}
        setAmt={setAmt}
        personal={per}
        setShow={setShow}
        show={show}
        open={showModal}
      />
      <TripSuccessModal vault open={secDone} />
    </div>
  );
};

export default Frame3;
