import React from "react";

const IconWithText = ({
  Icon,
  text,
  coloredText,
  rowReverse,
  onClick,
  className,
  disabled,
  dash,
}) => {
  return (
    <div
      onClick={disabled ? "" : onClick}
      className={`${
        disabled ? "bg-opacity-60 opacity-60" : ""
      }  cursor-pointer text-blue md:px-0 xs:px-[20px] md:py-0 xs:py-[5px] sm:text-[15px] md:text-[16px] xs:text-[14px] flex ${
        dash ? "xs:flex-row xs:gap-2" : "xs:flex-col xs:gap-0"
      } hover:text-blue-100 transition delay-75 duration-300 ease-in-out md:flex-row items-center md:gap-2 ${
        rowReverse && "flex-row-reverse"
      } ${className}`}
    >
      {Icon}
      <span className={`capitalize ${coloredText}`}> {text}</span>
    </div>
  );
};

export default IconWithText;
