import React, { Suspense } from "react";
import Loader from "components/loader/Loader";

const WithSuspense = (Component) => (props) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

export default WithSuspense;
