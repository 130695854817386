import React from "react";
import PropTypes from "prop-types";
import Switch from "react-switch";
import Loader from "components/loader/Loader";

const AppSwitch = ({
  checked,
  onChange,
  disabled,
  icon,
  title,
  label,
  loading,
  className,
}) => {
  return loading ? (
    <Loader />
  ) : (
    <div
      className={`${className} flex justify-between items-center  space-x-4`}
    >
      <div className={`flex justify-start items-center w-fit space-x-2`}>
        {icon}
        <div
          className={`flex flex-col justify-start items-start w-fit space-y-2`}
        >
          <span className="text-gray-100 text-xs medium-font">{title}</span>

          {label && (
            <span className="text-gray-100 text-[11px] regular-font">
              {label}
            </span>
          )}
        </div>
      </div>
      <div className="">
        <Switch
          onChange={onChange}
          checked={checked}
          offColor="#ADB2B8"
          onColor="#00509D"
          disabled={disabled}
          height={18}
          width={35}
          handleDiameter={16}
        />
      </div>
    </div>
  );
};
AppSwitch.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
export default AppSwitch;
