import React, { useState } from "react";
import { BiLoaderCircle } from "react-icons/bi";
import { ReactComponent as Close } from "../../assets/img/close-modal.svg";
import { Input } from "../input/Input";
import { REDEEM_COUPON } from "GraphQL/Mutations";
import { errorToast, successToast, warningToast } from "components/toast/toast";
import { useMutation } from "@apollo/client";
import { COUPONS } from "GraphQL/Queries";

const RedeemCoupon = ({ onClose }) => {
  const [code, setCode] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const [redeem] = useMutation(REDEEM_COUPON, {
    refetchQueries: [{ query: COUPONS }],
  });

  const redeemCouponCode = () => {
    setSubmitting(true);
    redeem({
      variables: {
        couponCode: code,
      },
    })
      .then((response) => {
        if (response.data.redeemCoupon.__typename === "BadRequest") {
          warningToast("Warning", response.data.redeemCoupon.message);
        }
        if (response.data.redeemCoupon.__typename === "Success") {
          successToast("Success", "Coupon Succesfully redeemed");
          onClose();
        }
      })
      .catch((error) => {
        errorToast("Error", error ? error?.message : "An error occured");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="absolute rounded-[8px] w-[100%] h-[100%] top-0 left-0 z-[1000] grid place-content-center">
      <div onClick={onClose} />
      <div className="bg-[#fff] relative p-[20px] rounded-[8px] ">
        <div
          onClick={onClose}
          className="cursor-pointer flex justify-end items-end "
        >
          <Close />
        </div>
        <div className="mt-[20px]">
          <div className="text-[#0E4F6D] text-[18px] sm:text-[24px] font-bold mb-[8px]">
            Redeem Coupon
          </div>
          <div className="text-[#667085] text-[14px] font-[500]">
            Enter coupon code, redeem and use anytime before it expires
          </div>

          <div className="mt-[30px]">
            <Input
              withLabel
              label={"Coupon Code"}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Enter/ Paste Coupon Code"
              labelClassName={"!font-[500] text-[#0E4F6D]"}
              className="w-full text-center h-[54px] placeholder:text-center border-[#e0e0e0]"
            />

            <div className="my-2 mx-auto md:ml-auto md:mx-0 w-full ">
              <button
                onClick={redeemCouponCode}
                type="submit"
                disabled={!code}
                className="bg-blue-100 text-[white] mt-[40px] font-bold items-center justify-center flex rounded-[8px] h-[50px] w-full"
              >
                {submitting ? (
                  <div className="flex items-center gap-[20px] ">
                    <BiLoaderCircle
                      size={32}
                      className="mx-auto animate-spin-slow"
                    />
                    <div>Redeeming...</div>
                  </div>
                ) : (
                  <div>Proceed to redeem</div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemCoupon;
