import { ReactComponent as ActiveHome } from "../../assets/img/interface-home-3--home-house-map-roof.svg";
import { ReactComponent as Home } from "../../assets/img/active-home.svg";
import { ReactComponent as ActivePackages } from "../../assets/img/active-package.svg";
import { ReactComponent as ActiveWishlist } from "../../assets/img/active-wishlist.svg";
import { ReactComponent as Wishlist } from "../../assets/img/interface-favorite-heart--reward-social-rating-media-heart-it-like-favorite-love.svg";
import { ReactComponent as Chat } from "../../assets/img/chat.svg";
import { ReactComponent as ActiveChat } from "../../assets/img/active-chat.svg";
import { ReactComponent as Packages } from "../../assets/img/travel-airport-baggage--check-baggage-travel-adventure-luggage-bag-checked.svg";
import {
  DASHBOARD,
  PACKAGES,
  WISHLIST,
  CHAT,
  PROFILE,
} from "../../router/router";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { images } from "constants";

export const profileList = [
  {
    id: 1,
    title: "My Profile",
    path: "/profile",
  },
  {
    id: 2,
    title: "Trip Vault",
    path: "/tripvault",
  },
  {
    id: 3,
    title: "Custom Trips",
    path: "/customtrips",
  },
  {
    id: 4,
    title: "Refer & Earn",
    path: "/referal",
  },
  {
    id: 5,
    title: "Coupons & Promotions",
    path: "/coupons",
  },
  {
    id: 6,
    title: "Transaction history",
    path: "/transactions",
  },
  {
    id: 7,
    title: "Support",
    path: "/support",
  },
  {
    id: 8,
    title: "Logout",
    action: "logout",
  },
];

export const headers = [
  {
    id: 1,
    title: "Dashboard",
    icon: <Home />,
    active: <ActiveHome />,
    route: DASHBOARD,
  },
  {
    id: 2,
    title: "Packages",
    icon: <Packages />,
    active: <ActivePackages />,
    route: PACKAGES,
  },
  {
    id: 3,
    title: "Wishlist",
    icon: <Wishlist />,
    active: <ActiveWishlist />,
    route: WISHLIST,
  },
  {
    id: 4,
    title: "Chat",
    icon: <Chat />,
    active: <ActiveChat />,
    route: CHAT,
  },
];

export const dropdown = [
  {
    id: 1,
    title: "My Profile",
    route: PROFILE,
  },
  {
    id: 2,
    title: "Trip Vault",
    route: "/tripvault",
  },
  {
    id: 3,
    title: "Custom Trips",
    route: "/customtrips",
  },
  {
    id: 4,
    title: "Refer & Earn",
    route: "/referal",
  },
  {
    id: 5,
    title: "Coupons & Promotions",
    route: "/coupons",
  },
  {
    id: 6,
    title: "Transaction history",
    route: "/transactions",
  },
  {
    id: 7,
    title: "Support",
    route: "/support",
  },
  {
    id: 8,
    title: "Logout",
    action: "action",
  },
];

export const footerIcons = [
  {
    id: 1,
    route: "https://facebook.com/travelbayhq",
    icon: images.fooFb,
  },
  {
    id: 2,
    route: "https://instagram.com/tbay.africahq",

    icon: images.fooIg,
  },
  {
    id: 3,
    route: "https://twitter.com/travelbayhq",

    icon: images.fooTwit,
  },
  {
    id: 4,
    route: "https://www.linkedin.com/company/travelbayhq/",
    icon: images.fooLi,
  },
];

export const intFooterIcons = [
  {
    id: 2,
    route: "https://instagram.com/tbayhq/",

    icon: images.fooIg,
  },
  {
    id: 4,
    route: "https://www.tiktok.com/@tbayhq",
    icon: images.tiktok,
  },
];

export const companyLink = [
  {
    id: 1,
    title: "What we offer",
    route: "/dashboard",
  },
  {
    id: 2,
    title: "How it works",
    route: "/dashboard",
  },
  {
    id: 3,
    title: "Exclusive offers",
    route: "/dashboard",
  },
];

export const footerLinks = [
  {
    id: 1,
    title: "Terms",
    route: "/legal/terms",
  },
  {
    id: 2,
    title: "Privacy",
    route: "/legal/policy",
  },
];

export const footerNumbers = {
  phone: [
    {
      id: 1,
      icon: images.phone,
      link: "tel:+234 809 237 8111",
      num: "+234 809 237 8111",
    },
    {
      id: 2,
      link: "tel:+234 809 238 4111",
      num: "+234 809 238 4111",
    },
  ],
  mail: {
    icon: images.mail,
    text: "Travelbayonline@gmail.com",
    link: "mailto:travelbayonline@gmail.com",
  },
  address: {
    icon: images.loca,
    text: "54b Adeniyi jones, ikeja, Lagos, Nigeria.",
  },
};

export const footerNumbersInt = {
  phone: [
    {
      id: 1,
      icon: images.phone,
      link: "tel:+1 347 407 5776",
      num: "+1 347 407 5776",
    },
  ],
  mail: {
    icon: images.mail,
    text: " info@travbelbayonline.com",
    link: "mailto: info@travbelbayonline.com",
  },
  address: {
    icon: images.loca,
    text: "220 E 23rd St, New York, NY 10010",
  },
};
