import React, { useRef, useState, useEffect } from "react";
import { images } from "constants";
import { BsDashLg, BsXLg } from "react-icons/bs";
import { Button } from "components/buttons";
import { Link } from "react-router-dom";
import {
  badWords,
  opt,
  checkingIn,
  greetings,
  responseGreetings,
  p1,
  p2,
  p3,
  p4,
  p8,
} from "components/prompts";

const Bot = ({ isOpen, onClose }) => {
  const modalContentRef = useRef(null);

  const [convo, setConvo] = useState({
    botMessage: "",
    humanMessage: "",
  });
  const [chatHistory, setChatHistory] = useState(() => {
    const storedChatHistory = sessionStorage.getItem("chatHistory");
    return storedChatHistory ? JSON.parse(storedChatHistory) : [];
  });
  const close = () => {
    onClose();
    setConvo({
      ...convo,
      botMessage: "",
      humanMessage: "",
    });
    setChatHistory([]);
    sessionStorage.removeItem("chatHistory");
  };
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();

  const [dot, setDot] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDot((dot) => (dot + 1) % 4);
    }, 300);
    return () => clearInterval(interval);
  }, []);

  const handleInput = (e) => {
    e.preventDefault();
    const inputVal = inputRef.current.value.toLowerCase().replace("?", "");
    sessionStorage.setItem("chatHistory", JSON.stringify(chatHistory));

    if (inputVal) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
    if (!loading) {
      if (inputVal.match(badWords)) {
        setConvo((prevState) => ({
          ...prevState,
          botMessage: "Please do not use bad words",
        }));
      } else if (inputVal.match(greetings)) {
        setConvo((prevState) => ({
          ...prevState,
          botMessage: "Hello there! How are you doing today?",
        }));
      } else if (inputVal.match(p1)) {
        setConvo((prevState) => ({
          ...prevState,
          botMessage: "The price for every trip is located on the package card",
        }));
      } else if (inputVal.match(p2)) {
        setConvo((prevState) => ({
          ...prevState,
          botMessage:
            "Check out our package page.\nSelect a travel package of preference.\nWe have 2 different payment method to verify your booking process.\nYou can either save installmentally or Pay all at once.",
        }));
      } else if (inputVal.match(p3)) {
        setConvo((prevState) => ({
          ...prevState,
          botMessage: "You can apply for a visa using the link below",
        }));
      } else if (inputVal.match(p4)) {
        setConvo((prevState) => ({
          ...prevState,
          botMessage:
            "We have 2 options to select from when paying for a trip.\nYou can pay installmentally or pay all at once",
        }));
      } else if (inputVal.match(p8)) {
        setConvo((prevState) => ({
          ...prevState,
          botMessage: "You can contact our customer service through",
        }));
      } else if (inputVal.match(responseGreetings)) {
        setConvo((prevState) => ({
          ...prevState,
          botMessage: "Great to hear!, what can I do for you today?",
        }));
      } else if (inputVal.match(checkingIn)) {
        setConvo((prevState) => ({
          ...prevState,
          botMessage:
            "I am good as well, thank you for asking. What can I do for you?",
        }));
      } else if (inputVal !== "") {
        setConvo((prevState) => ({
          ...prevState,
          botMessage: "I do not understand.",
        }));
      }
    }
    setConvo((prevState) => ({
      ...prevState,
      humanMessage: inputVal,
    }));
    inputRef.current.value = "";
  };
  useEffect(() => {
    if (isOpen || convo.humanMessage) {
      if (modalContentRef.current) {
        modalContentRef.current.scrollTo({
          top: modalContentRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  }, [isOpen, convo.humanMessage, chatHistory]);

  useEffect(() => {
    const storedChatHistory = sessionStorage.getItem("chatHistory");
    if (storedChatHistory) {
      setChatHistory(JSON.parse(storedChatHistory));
    }
  }, []);

  useEffect(() => {
    if (convo.humanMessage && convo.botMessage) {
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { botMessage: convo.botMessage, humanMessage: convo.humanMessage },
      ]);
    }
  }, [convo.botMessage, convo.humanMessage]);

  const accType = sessionStorage.getItem("account_type");

  return (
    <div>
      {isOpen && (
        <div className="fixed flex flex-col rounded-[8px] drop-shadow-2xl z-[555] text-[#fff] xs:bottom-16 xs:top-16 sm:top-[unset] md:bottom-12 xs:right-6 xs:left-8 sm:left-[unset] sm:right-8 bg-[#F2F4F7] xs:w-[fit] sm:w-[400px]">
          <div className="bg-[#1D2939] rounded-t-[8px] p-[12px] flex items-center justify-between">
            <div className="flex items-center gap-[8px]">
              <img src={images.botpic} />

              <div>
                <div className="font-bold leading-[20px]">Travelbaybot</div>
                <div className="text-[#D0D5DD] text-[13px]">
                  Typically replies within 3 minutes
                </div>
              </div>
            </div>

            <div className="flex items-center gap-[20px] text-[#EAECF0]">
              <div onClick={onClose} className="cursor-pointer">
                <BsDashLg />
              </div>
              <div onClick={close} className="cursor-pointer">
                <BsXLg />
              </div>
            </div>
          </div>

          <div
            ref={modalContentRef}
            className="bot mt-[8px] pb-[60px] px-[14px] h-[450px] overflow-auto"
          >
            <div className="text-[#5D6D8F] text-center font-medium mb-[15px]">
              Today
            </div>

            <div className="ml-[44px] flex w-fit mb-[16px] items-center bg-[#fff] rounded-[8px] gap-[8px] py-[8px] px-[12px]">
              <div className="text-[#093549] font-medium text-[14px]">
                {" "}
                Hello there
              </div>
              <img src={images.wave} />
            </div>

            <div className="ml-[44px] flex w-fit mb-[16px] items-center bg-[#fff] rounded-[8px] gap-[8px] py-[8px] px-[12px]">
              <div className="text-[#093549] font-medium text-[14px]">
                {" "}
                I’m Travelbaybot, your virtual assistant, Thank you for reaching
                out to us!
              </div>
              <img src={images.wave} />
            </div>

            <div className="flex items-end gap-[8px]">
              <img src={images.botpic} />
              <div className="flex w-fit items-center bg-[#fff] rounded-[8px] gap-[8px] py-[8px] px-[12px]">
                <div className="text-[#093549] font-medium text-[14px]">
                  {" "}
                  Choose a topic below or ask me a short, direct question.
                </div>
                <img src={images.thumb} />
              </div>
            </div>

            <div className="flex my-[25px] font-bold text-[#093549] text-[14px] flex-wrap gap-[8px] items-center">
              {opt.map((data) => (
                <div
                  key={data.id}
                  onClick={() =>
                    data.id === 1
                      ? setConvo({
                          ...convo,
                          humanMessage: "Tell me about custom trips",
                          botMessage:
                            "Can't find a package in our vault that interests you? \n You can create a customized trip that suits your desires.\n ",
                        })
                      : data.id === 2
                      ? setConvo({
                          ...convo,
                          humanMessage: "What are the packages available?",
                          botMessage:
                            "Traveling but unsure of what activities to partake in or places to visit while there? \n Let's take the stress off of you. \n\n",
                        })
                      : data.id === 4
                      ? setConvo({
                          ...convo,
                          humanMessage: "How do I make payment for a trip?",
                          botMessage:
                            "We ensure our customers' trips are both affordable and memorable because we recognize that travelers are sometimes on a tight budget. \n You can choose to save up installmentally for a trip or pay all at once \n",
                        })
                      : data.id === 6
                      ? setConvo({
                          ...convo,
                          humanMessage: "Visa Application",
                          botMessage:
                            "It can be challenging and stressful to know what to do next when applying for a visa. \n It can be challenging and stressful to know what to do next when applying for a visa\n\n",
                        })
                      : data.id === 7
                      ? setConvo({
                          ...convo,
                          humanMessage: "Tell me about the referrals bonus",
                          botMessage: `Refer travel customers to our website and earn up to 200000 points in Travel Credits!\nYour unique referral code can be found in your`,
                        })
                      : data.id === 3
                      ? setConvo({
                          ...convo,
                          humanMessage: "I need help from the support team",
                          botMessage:
                            "Are you planning a trip or have a question for us? \n We'll be happy to help you every step of the way. \n\n",
                        })
                      : ""
                  }
                  className={`${
                    data.id === 3 ||
                    data.id === 4 ||
                    data.id === 5 ||
                    data.id === 6 ||
                    data.id === 7
                      ? "mt-[10px]"
                      : "mt-0"
                  } border cursor-pointer border-[#D0D5DD] py-[7px] px-[10px] rounded-full flex items-center gap-[8px]`}
                >
                  <img src={data.icon} />
                  <div>{data.text}</div>
                </div>
              ))}
            </div>

            {chatHistory?.map((data, i) => (
              <>
                <div
                  key={i}
                  className="flex mb-[16px] items-end justify-end gap-[8px]"
                >
                  <div className="min-w-[60px] max-w-[80%] bg-[#fff] rounded-[8px] py-[8px] px-[12px]">
                    <div className="text-[#093549] font-medium text-[14px]">
                      {" "}
                      {data?.humanMessage}
                    </div>
                  </div>
                  <img src={images.botpic} />
                </div>
                {i === chatHistory?.length - 1 && loading ? (
                  ""
                ) : (
                  <div className="flex mb-[16px] items-end justify-start gap-[8px]">
                    <div />
                    <img src={images.botpic} />
                    <div className="whitespace-pre-line min-w-[60px] max-w-[70%] bg-[#fff] rounded-[8px] py-[8px] px-[12px]">
                      <div className="text-[#093549] font-medium text-[14px]">
                        {" "}
                        <div>{data?.botMessage}</div>
                        <div>
                          {data?.botMessage.includes("but unsure") ? (
                            <>
                              <span>
                                Check out our{" "}
                                <Link to="/packages" className="text-blue-100">
                                  package
                                </Link>
                              </span>
                            </>
                          ) : data?.botMessage.includes("customized trip") ? (
                            <>
                              <span>
                                <Link to="/plan-trip" className="text-blue-100">
                                  Plan your trip.
                                </Link>{" "}
                                <br />
                                <span>
                                  {" "}
                                  You can also access your requested trips{" "}
                                  <Link
                                    className="text-blue-100"
                                    to="/customtrips"
                                  >
                                    here
                                  </Link>
                                </span>
                              </span>
                            </>
                          ) : data?.botMessage.includes("for a visa") ? (
                            <>
                              <span>
                                <Link
                                  target="_blank"
                                  to="https://forms.gle/HwsyyasRkipXtmeP8"
                                  className="text-blue-100"
                                >
                                  Request Visa Assistance
                                </Link>
                              </span>
                            </>
                          ) : data?.botMessage.includes("not understand") ? (
                            <>
                              <span>
                                Please reach out to our{" "}
                                <Link
                                  target="_blank"
                                  to="/"
                                  className="text-blue-100"
                                >
                                  customer service
                                </Link>
                              </span>
                            </>
                          ) : data?.botMessage.includes("for every") ? (
                            <>
                              <br />
                              <span>
                                Check our{" "}
                                <Link
                                  target="_blank"
                                  to="/packages"
                                  className="text-blue-100"
                                >
                                  packages
                                </Link>{" "}
                                to see available prices
                              </span>
                            </>
                          ) : data?.botMessage.includes("save up") ? (
                            <>
                              <span>
                                • Go to our{" "}
                                <Link to="/packages" className="text-blue-100">
                                  package
                                </Link>
                                .<br /> • Select a package. <br />• There will
                                be two options provided, You can either "Save
                                Towards Package" or "Book now!" <br />
                                <br />
                                <span>
                                  You can also view your transaction history{" "}
                                  <Link
                                    className="text-blue-100"
                                    to="/transactions"
                                  >
                                    here
                                  </Link>
                                </span>
                              </span>
                            </>
                          ) : data?.botMessage.includes("can contact") ? (
                            <>
                              <span>
                                <Link to="/" className="text-blue-100">
                                  whatsapp
                                </Link>
                                <br />
                                <Link to="/" className="text-blue-100">
                                  instagram
                                </Link>
                                <br />
                                <Link to="/" className="text-blue-100">
                                  chat media
                                </Link>
                              </span>
                            </>
                          ) : data?.botMessage.includes("instantly") ? (
                            <>
                              <span>
                                <Link to="/referal" className="text-blue-100">
                                  Referral Dashboard
                                </Link>
                              </span>
                            </>
                          ) : data?.botMessage.includes("have a question") ? (
                            <>
                              <span>
                                <Link
                                  target="_blank"
                                  to="mailto:Info@Travelbayonline.Com"
                                  className="text-blue-100"
                                >
                                  Contact Support
                                </Link>
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
            {loading && (
              <div className="flex items-end justify-start gap-[8px]">
                <div />
                <img src={images.botpic} />
                <div className="min-w-[60px] max-w-[25%] bg-[#fff] rounded-[8px] py-[8px] px-[12px]">
                  <div
                    className={`pending pending-${dot} text-[23px] text-[#000]`}
                  />
                </div>
              </div>
            )}
          </div>

          <form className="mt-auto py-4 w-full gap-[20px] bg-[#1D2939] rounded-b-[8px] px-[14px] flex items-center justify-between">
            <input
              ref={inputRef}
              placeholder="Type a message"
              className="w-[80%] h-[35px] bg-[unset] px-[10px] border-0"
            />

            <div className="w-[14%]">
              <Button
                type="submit"
                onClick={handleInput}
                className="h-10 flex items-center justify-center"
                isBlue
                withIcon
                icon={<img src={images.send} />}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Bot;
