import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { useNavigate } from "react-router-dom";

const NetworkStatusMonitor = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const client = useApolloClient();
  const navigate = useNavigate();

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      // Redirect to the desired page when offline
      navigate("/offline");
    } else {
      // Clear the Apollo cache when the network is restored
      client.resetStore();
    }
  }, [isOnline, navigate, client]);

  return null;
};

export default NetworkStatusMonitor;
